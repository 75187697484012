import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'mcv-formly-ng-field-select',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <ng-select [items]="to.options"
                   class="size-medium status-basic"
                   [ngClass]="!formControl?.valid? 'ng-invalid':'' "
                   appendTo="body"
                   bindValue="value"
                   bindLabel="label"
                   [formControl]="formControl"
                   [formlyAttributes]="field"
        ></ng-select>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
export class FormlyNgFieldSelectComponent extends FieldType {
}
