<nb-accordion>
    <nb-accordion-item>
        <nb-accordion-item-header>Filtre</nb-accordion-item-header>
        <nb-accordion-item-body>
            <form [formGroup]="searchFilter">
                <div class="row">
                    <div class="col-5">Par nom</div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <input formControlName="filter" fullWidth nbInput placeholder="Recherchez par nom..."
                               type="search"/>
                    </div>
                </div>
            </form>
        </nb-accordion-item-body>
    </nb-accordion-item>
</nb-accordion>
<br/>
<nb-card>
    <nb-card-body>
        <mcv-user-list [reflectUrl]="false" [searchFilter]="searchFilter"></mcv-user-list>
    </nb-card-body>
</nb-card>
