import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {McvnotifierService} from '@mcv/ui';
import {map, tap} from 'rxjs/operators';
import {TrzToolsService} from '@crm/app/trz-tools/services/trz-tools.service';

@Component({
    selector: 'mcv-legal-annual-turn-over-select',
    templateUrl: './legal-annual-turn-over-select.component.html',
    styleUrls: ['./legal-annual-turn-over-select.component.scss']
})
export class LegalAnnualTurnOverSelectComponent implements OnInit, ControlValueAccessor {
    @Input() value: string = null;
    @Input() disabled: boolean;
    items: { value: string, label: string }[];
    loading = false;

    constructor(private trzToolsService: TrzToolsService,
                @Self() @Optional() public control: NgControl,
                private notifierService: McvnotifierService) {
        this.control && (this.control.valueAccessor = this);
    }

    onChange: any = (val: number) => {
    };

    onTouch: any = (val: number) => {
    };

    ngOnInit(): void {
        if (!this.items) {
            this.loadItems();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }

    loadItems() {
        this.loading = true;
        this.trzToolsService
            .listAnnualTurnover()
            .pipe(
                map(r => r.data),
                map(r => Object.keys(r).map(p => ({value: p, label: `${r[p]}`}))),
                tap(_ => this.loading = false)
            )
            .toPromise()
            .then(r => this.items = r)
            .catch(err => {
                this.notifierService.error(err);
                this.loading = false;
            });
    }
}


