import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from '@crm/app/auth/pages/error/error.component';
import {RegisterComponent} from '@crm/app/auth/pages/register/register.component';
import {LoginComponent} from '@crm/app/auth/pages/login/login.component';
import {IndexComponent} from '@crm/app/auth/pages/index/index.component';
import {LogoutComponent} from '@crm/app/auth/pages/logout/logout.component';

const routes: Routes = [
    {
        path: 'auth',
        component: IndexComponent,
        children: [
            {path: 'login', component: LoginComponent},
            {path: 'logout', component: LogoutComponent},
            {path: 'register', component: RegisterComponent},
            {path: 'error', component: ErrorComponent}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
