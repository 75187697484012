import {Component, Input, OnInit} from '@angular/core';
import {AffiliateUser} from '@mcv/core';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';
import {
    AffiliateUserDetailComponent
} from '@crm/app/affiliate/ui/affiliate-user-detail/affiliate-user-detail.component';

@Component({
    selector: 'mcv-affiliate-user-avatar',
    templateUrl: './affiliate-user-avatar.component.html',
    styleUrls: ['./affiliate-user-avatar.component.scss']
})
export class AffiliateUserAvatarComponent implements OnInit {

    @Input() affiliateUser: AffiliateUser;
    @Input() displayName = true;
    @Input() displayId = false;
    @Input() displayEmail = false;
    @Input() displayPhone = false;
    @Input() displayMobile = false;
    @Input() displayDetail = false;
    @Input() displayStatus = false;

    constructor(private windowService: WindowManagerService) {
    }

    ngOnInit(): void {
    }

    openDetail() {
        this.windowService.open(AffiliateUserDetailComponent, {
            windowClass: 'large',
            title: `Détail utilisateur affilié #${this.affiliateUser.id}`,
            context: {affiliate_user_public_id: this.affiliateUser.public_id}
        }
        );
    }
}
