import {InjectionToken} from '@angular/core';
import {CardTransactionService} from '@crm/app/cse-employe-cards/services/card-transaction.service';
import {AdminTransactionService} from '@crm/app/shared/services/admin-transaction.service';
import {MerchantCategoryService} from '@crm/app/tools/services/merchant-category.service';
import {CseEmployeCardService} from '@cseemploye/app/shared/services/cse-employe-card.service';
import {CseFacture} from '@mcv/core';
import {
    AffiliateService,
    AffiliateTpeService,
    AffiliateUserService,
    CategoryService,
    ContributionService,
    ContributionTopupService,
    CseCommandeLineService,
    CseCommandeService,
    CseDocumentService,
    CseEmployeService,
    CseFactureService,
    CseImportService,
    CseService,
    CseUserService,
    PayinWhiteListService,
    PerimetercategoryService,
    PushNotificationMessageService,
    PushNotificationReceiptService,
    RefundRequestsService
} from '@mcv/coreservices';
import {CseEmployeTopupService} from '@crm/app/cse-employe-topup/services/cse-employe-topup.service';

export const CseCommandeDataStore = new InjectionToken<string>('CseCommande');
export const CseCommandeLineDataStore = new InjectionToken<string>('CseCommandeLine');
export const CseDataStore = new InjectionToken<string>('Cse');
export const CseEmployeDataStore = new InjectionToken<string>('CseEmploye');
export const CseUserDataStore = new InjectionToken<string>('CseUser');
export const CseFactureDataStore = new InjectionToken<string>('CseFacture');
export const AffiliateDataStore = new InjectionToken<string>('Affiliate');
export const AffiliateTpeDataStore = new InjectionToken<string>('AffiliateTpe');
export const AffiliateUserDataStore = new InjectionToken<string>('AffiliateUser');
export const TransactionStore = new InjectionToken<string>('Transaction');
export const CseDocumentDataStore = new InjectionToken<string>('CseDocument');
export const CategoryDataStore = new InjectionToken<string>('Category');
export const PerimetercategoryDataStore = new InjectionToken<string>('Perimetercategory');
export const MerchantCategoryDataStore = new InjectionToken<string>('MerchantCategory');
export const CseEmployeCardDataStore = new InjectionToken<string>('CseEmployeCard');
export const CardtransactionDatStore = new InjectionToken<string>('Cardtransaction');
export const CseImportDataStore = new InjectionToken<string>('CseImport');
export const CseEmployeTopupDataStore = new InjectionToken<string>('CseEmployeTopup');
export const RefundRequestsDataStore = new InjectionToken<string>('RefundRequests');
export const PayinWhiteListDataStore = new InjectionToken<string>('PayinWhiteList');
export const ContributionDataStore = new InjectionToken<string>('ContributionDataList');
export const ContributionTopupDataStore = new InjectionToken<string>('ContributionTopupDataList');
export const PushNotificationMessageDataStore = new InjectionToken<string>('PushNotificationMessage');
export const PushNotificationReceiptDataStore = new InjectionToken<string>('PushNotificationReceipt');

export const mcvStore: { token: InjectionToken<string>, repository: any }[] = [
    {token: CseCommandeDataStore, repository: CseCommandeService},
    {token: CseDataStore, repository: CseService},
    {token: CseCommandeLineDataStore, repository: CseCommandeLineService},
    {token: AffiliateDataStore, repository: AffiliateService},
    {token: AffiliateTpeDataStore, repository: AffiliateTpeService},
    {token: AffiliateUserDataStore, repository: AffiliateUserService},
    {token: TransactionStore, repository: AdminTransactionService},
    {token: CseEmployeDataStore, repository: CseEmployeService},
    {token: CseUserDataStore, repository: CseUserService},
    {token: CseDocumentDataStore, repository: CseDocumentService},
    {token: CategoryDataStore, repository: CategoryService},
    {token: PerimetercategoryDataStore, repository: PerimetercategoryService},
    {token: MerchantCategoryDataStore, repository: MerchantCategoryService},
    {token: CseEmployeCardDataStore, repository: CseEmployeCardService},
    {token: CardtransactionDatStore, repository: CardTransactionService},
    {token: CseImportDataStore, repository: CseImportService},
    {token: CseEmployeTopupDataStore, repository: CseEmployeTopupService},
    {token: CseFactureDataStore, repository: CseFactureService},
    {token: RefundRequestsDataStore, repository: RefundRequestsService},
    {token: PayinWhiteListDataStore, repository: PayinWhiteListService},
    {token: ContributionDataStore, repository: ContributionService},
    {token: ContributionTopupDataStore, repository: ContributionTopupService},
    {token: PushNotificationMessageDataStore, repository: PushNotificationMessageService},
    {token: PushNotificationReceiptDataStore, repository: PushNotificationReceiptService}
];
