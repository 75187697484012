<nb-card>
    <nb-card-header>Se connecter</nb-card-header>
    <nb-card-body>
        <nb-alert *ngIf="error" status="danger">
            {{error}}
        </nb-alert>
        <button (click)="login()"
                [nbSpinner]="loading"
                fullWidth
                nbButton
                outline
                size="large"
                status="primary">
            Accéder à la plateforme via le compte Google
        </button>
    </nb-card-body>
</nb-card>
