import {Directive, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MetaPaging} from '@mcv/core';
import {Subject, Subscription} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {FormGroup} from '@angular/forms';
import {DataStore} from '../app/store/services/store.service';

@Directive()
export abstract class CrmTableComponent<T> implements OnInit, OnDestroy {
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @Input() listenUrl = false;
    @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Output() sortChange: EventEmitter<any> = new EventEmitter<any>();
    store: DataStore<T>;
    paging$: Subject<MetaPaging>;
    limit = 10;
    page = 0;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    protected filterForm: FormGroup;
    protected subscription: Subscription = new Subscription();

    protected constructor() {
    }

    @Input() set searchFilter(value: FormGroup) {
        this.filterForm = value;
        this.store.linkFilterForm(value);
    }

    @Input() set reflectUrl(value: boolean) {
        this.store.reflectUrl = value;
    }

    ngOnInit(): void {
        this.sort.sort(({id: 'date_create', start: 'desc'}) as MatSortable);

        this.paging$ = this.store.paging$;
        if (this.sort) {
            this.subscription.add(this.sort.sortChange.subscribe(s => {
                this.store.changeSort(s);
                this.sortChange.emit(s);
            }));
        }
        if (this.store.reflectUrl) {
            this.store.readFromUrl();
        }
        this.store.readDataList();
    }

    ngOnDestroy(): void {
        this.store.disconnect(null);
        this.subscription.unsubscribe();
    }

    changePage($event: PageEvent) {
        this.store.changePage($event);
        this.pageChange.emit($event);
    }

}
