import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {Mixin, settings} from 'ts-mixer';
import {
    LegalNumberOfEmployeeRangeSelectComponent
} from '@crm/app/shared/ui/legal-number-of-employee-range-select/legal-number-of-employee-range-select.component';

settings.initFunction = 'init';

@Component({
    selector: 'mcv-formly-legal-number-of-employee-range-select',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <ng-select [items]="items"
                   class="size-medium status-basic"
                   [ngClass]="!formControl?.valid?'ng-invalid':''"
                   [loading]="loading"
                   appendTo="body"
                   bindValue="value"
                   bindLabel="label"
                   [formControl]="formControl"
                   [formlyAttributes]="field"
        ></ng-select>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
// @ts-ignore
export class FormlyLegalNumberOfEmployeeRangeSelectComponent extends Mixin(FieldType, LegalNumberOfEmployeeRangeSelectComponent) {

}
