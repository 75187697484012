import {HttpParams} from '@angular/common/http';

export const buildHttpParamsFilter = (httpParams: HttpParams, values: Record<string, any>): HttpParams => {
    // tslint:disable-next-line:forin
    for (const value in values) {
        const newVar = values[value];
        if (newVar !== undefined && newVar !== null) {
            httpParams = httpParams.set(value, newVar);
        }
    }
    return httpParams;
};

export const buildSortHttpParams = (httpParams: HttpParams, sortDirection?, sortField?): HttpParams => {
    return httpParams.append('direction', sortDirection)
        .append('sort', sortField);
};
