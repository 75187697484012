import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {WindowManagerConfigOptions} from '@crm/app/window-manager/interfaces/WindowManagerConfigOptions';
import {WINDOW_CONFIG_TOKEN} from '@crm/app/window-manager/utils/config-token';
import {ResizableDirective} from './directives/resizable.directive';
import {TaskBarComponent} from './ui/task-bar/task-bar.component';
import {WindowManagerModalComponent} from './ui/window-manager-modal/window-manager-modal.component';

@NgModule({
    declarations: [
        WindowManagerModalComponent,
        TaskBarComponent,
        ResizableDirective
    ],
    imports: [
        CommonModule,
        DragDropModule
    ]
})
export class WindowManagerModule {
    static forRoot(config?: WindowManagerConfigOptions): ModuleWithProviders<WindowManagerModule> {
        const providers: any[] = [];
        if (config) {
            providers.push({
                provide: WINDOW_CONFIG_TOKEN,
                useValue: config
            });
        }
        return {
            ngModule: WindowManagerModule,
            providers
        };
    }
}
