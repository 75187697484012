import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AffiliateUser} from '@mcv/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AffiliateUserService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';
import {FormlyFieldConfig} from '@ngx-formly/core';

@Component({
    selector: 'mcv-affiliate-user-view',
    templateUrl: './affiliate-user-view.component.html',
    styleUrls: ['./affiliate-user-view.component.scss']
})
export class AffiliateUserViewComponent implements OnInit, OnChanges {
    @Input() affiliateUser: AffiliateUser;
    affiliateForm: FormGroup = this.fb.group({});
    saving = false;


    fields: FormlyFieldConfig[] = [
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'État'},
            fieldGroup: [
                {
                    key: 'state',
                    className: 'col-6',
                    type: 'select',
                    templateOptions: {
                        label: 'Status',
                        placeholder: '',
                        required: true,
                        options: [
                            {label: 'draft', value: 'draft'},
                            {label: 'draft_kyc', value: 'draft_kyc'},
                            {label: 'draft_kyc_done', value: 'draft_kyc_done'},
                            {label: 'sync', value: 'sync'},
                            {label: 'toclose', value: 'toclose'},
                            {label: 'closed', value: 'closed'},
                            {label: 'none', value: 'none'}
                        ]
                    }
                },
                {
                    key: 'email_verified',
                    className: 'col',
                    type: 'checkbox',
                    templateOptions: {
                        label: 'Email vérifié',
                        placeholder: 'Le mail a été vérifié?',
                        required: false
                    }
                }
            ]
        },
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Civilité'},
            fieldGroup: [
                {
                    key: 'title',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Title',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'firstname',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Prénom',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'middleNames',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'second prénom',
                        placeholder: '',
                        required: false
                    }
                },
                {
                    key: 'lastname',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Nom',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'birthday',
                    className: 'col-6',
                    type: 'date',
                    templateOptions: {
                        label: 'Date de naissance',
                        placeholder: '',
                        required: true,
                        type: 'YYYY-MM-DD'
                    }
                },
                {
                    key: 'placeOfBirth',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Ville de naissance',
                        placeholder: '',
                        required: false
                    }
                },
                {
                    key: 'nationality',
                    className: 'col-6',
                    type: 'country',
                    templateOptions: {
                        label: 'Nationalité',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'nationalityOther',
                    className: 'col-6',
                    type: 'country',
                    templateOptions: {
                        label: 'Autre nationalité',
                        placeholder: '',
                        required: false
                    }
                }
            ]
        },
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Adresse'},
            fieldGroup: [
                {
                    key: 'address1',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Adresse',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'address2',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Adresse 2',
                        placeholder: '',
                        required: false,
                        maxLength: 50
                    }
                },
                {
                    key: 'postcode',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Code postal',
                        placeholder: '',
                        type: 'number',
                        required: true
                    }
                },
                {
                    key: 'city',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Ville',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'country',
                    className: 'col-6',
                    type: 'country',
                    templateOptions: {
                        label: 'Pays',
                        placeholder: '',
                        required: true
                    }
                }
            ]
        },
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Contact'},
            fieldGroup: [
                {
                    key: 'phone',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Téléphone',
                        placeholder: '',
                        type: 'tel',
                        required: false
                    }
                },
                {
                    key: 'mobile',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Mobile',
                        placeholder: '',
                        type: 'tel',
                        required: false
                    }
                },
                {
                    key: 'email',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Code postal',
                        placeholder: '',
                        type: 'email',
                        required: true
                    }
                }
            ]
        },
        {
            fieldGroupClassName: 'col',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Activation du compte'},
            fieldGroup: [
                {
                    fieldGroupClassName: 'row',
                    wrappers: ['fieldset'],
                    templateOptions: {label: 'Activation du compte admin'},
                    fieldGroup: [
                        {
                            key: 'email_code',
                            className: 'col-6',
                            type: 'input',
                            templateOptions: {
                                label: 'Code de validation',
                                placeholder: '',
                                required: false
                            }
                        },
                        {
                            key: 'email_code_expire',
                            className: 'col-6',
                            type: 'datetime',
                            templateOptions: {
                                label: 'Date de validité du code',
                                type: 'YYYY-MM-DDThh:mm:ssZZ'
                            }
                        }
                    ]
                },
                {
                    fieldGroupClassName: 'row',
                    wrappers: ['fieldset'],
                    templateOptions: {label: 'Activation du compte non admin'},
                    fieldGroup: [
                        {
                            key: 'secret',
                            className: 'col-6',
                            type: 'input',
                            templateOptions: {
                                label: 'Code de validation',
                                placeholder: '',
                                required: false
                            }
                        },
                        {
                            key: 'secret_expire',
                            className: 'col-6',
                            type: 'datetime',
                            templateOptions: {
                                label: 'Date de validité du code',
                                type: 'YYYY-MM-DDThh:mm:ssZZ'
                            }
                        }
                    ]
                }
            ]
        }
    ];


    constructor(private affiliateUserService: AffiliateUserService,
                private notifierService: McvnotifierService,
                private fb: FormBuilder) {
    }

    async ngOnInit(): Promise<void> {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.affiliate) {
            this.affiliateForm.patchValue(changes.affiliate.currentValue);
        }
    }

    async saveForm() {
        try {
            this.saving = true;
            const formData = this.affiliateForm.value;
            await this.affiliateUserService.edit(this.affiliateUser.public_id, formData)
                .toPromise();
            this.notifierService.success('Utilisateur mis à jour');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }
}
