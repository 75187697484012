import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndexComponent} from './pages/index/index.component';
import {ContributionListComponent} from './ui/contribution-list/contribution-list.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {NbAccordionModule, NbButtonModule, NbCardModule, NbIconModule, NbSpinnerModule} from '@nebular/theme';
import {CdkTableModule} from '@angular/cdk/table';
import {MatSortModule} from '@angular/material/sort';
import {
    ContributionTopupListComponent
} from '@crm/app/contributions/ui/contribution-topup-list/contribution-topup-list.component';

@NgModule({
    declarations: [IndexComponent, ContributionListComponent, ContributionTopupListComponent],
    exports: [
        ContributionListComponent,
        ContributionTopupListComponent
    ],
    imports: [
        CommonModule,
        MatPaginatorModule,
        NbSpinnerModule,
        CdkTableModule,
        MatSortModule,
        NbCardModule,
        NbAccordionModule,
        NbIconModule,
        NbButtonModule
    ]
})
export class ContributionsModule {
}
