<nb-accordion>
    <nb-accordion-item>
        <nb-accordion-item-header>Administrateur du compte</nb-accordion-item-header>
        <nb-accordion-item-body>
            <mcv-affiliate-user-avatar [affiliateUser]="affiliate.affiliate_user[0]"
                                       [displayDetail]="true"
                                       [displayEmail]="true"
                                       [displayPhone]="true"
                                       [displayStatus]="true"
            ></mcv-affiliate-user-avatar>
        </nb-accordion-item-body>
    </nb-accordion-item>
</nb-accordion>
<form (ngSubmit)="saveForm()" [formGroup]="affiliateForm">
    <formly-form [fields]="fields" [form]="affiliateForm" [model]="affiliate"></formly-form>
    <br/>
    <button [disabled]="saving || !affiliateForm.valid"
            [nbSpinner]="saving"
            fullWidth
            nbButton
            status="primary"
            type="submit">
        Enregistrer les modifications
    </button>
</form>
