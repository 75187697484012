import {Component, Inject, OnInit} from '@angular/core';
import {Affiliate} from '@mcv/core';
import {AffiliateDataStore} from '@crm/app/store/store';
import {DataStore} from '@crm/app/store/services/store.service';
import {CrmTableComponent} from '@crm/interfaces/CrmTableComponent';

@Component({
    selector: 'mcv-affiliate-list',
    templateUrl: './affiliate-list.component.html',
    styleUrls: ['./affiliate-list.component.scss']
})
export class AffiliateListComponent extends CrmTableComponent<Affiliate> implements OnInit {

    affiliate: Affiliate;
    displayedColumns: string[] = [
        'id',
        'legalName',
        'contact',
        'state',
        'actions'
    ];

    constructor(@Inject(AffiliateDataStore) public store: DataStore<Affiliate>) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }
}
