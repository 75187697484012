import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {Mixin, settings} from 'ts-mixer';
import {ButtonFileChooserComponent} from '../button-file-chooser/button-file-chooser.component';
import {ReadFile} from 'ngx-file-helpers';

settings.initFunction = 'init';

@Component({
    selector: 'mcv-formly-file-chooser',
    template: `
        <div class="d-flex">
            <div>
                <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
                <br/>
                <button [disabled]="uploading"
                        nbButton
                        size="medium"
                        type="button"
                        #filePicker="ngxFilePicker"
                        ngxFilePicker="'readMode'"
                        multiple
                        [accept]='to.extensions'
                        (filePick)="onFilePicked($event)">
                    Parcourir l'ordinateur
                </button>
                <val-errors controlName="{{key}}"></val-errors>
            </div>
            <button *ngIf="file && rawData && displayPreview" 
                    class="ml-3"
                    type="button"
                    (click)="openPreview()">
                <iframe [src]="rawData | safeHtml:'resourceUrl'"
                        disabled
                        frameborder="0"
                        scrolling="false"
                        width="100"
                        height="100"></iframe>
            </button>
        </div>
    `
})
// @ts-ignore
export class FormlyFileChooserComponent extends Mixin(FieldType, ButtonFileChooserComponent) {

    onFilePicked($event: ReadFile) {
        this.formControl.patchValue($event.underlyingFile);
        this.onChange($event.underlyingFile);
        this.rawData = $event.content;
        this.file = $event;
    }
}
