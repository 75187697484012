import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'mcv-formly-ng-field-date',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <mcv-admin-datepicker 
                [formControl]="formControl"
                [formlyAttributes]="field" 
                [format]="to.type"></mcv-admin-datepicker>
        <val-errors controlName="{{key}}"></val-errors>
    `
})

export class FormlyNgFieldDateComponent extends FieldType {
}
