import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '@crm/app/auth/services/auth.service';
import {NbComponentSize} from '@nebular/theme/components/component-size';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-button-logas',
    templateUrl: './button-logas.component.html',
    styleUrls: ['./button-logas.component.scss']
})
export class ButtonLogasComponent implements OnInit {
    @Input() name: string;
    @Input() id: number;
    @Input() size: NbComponentSize = 'small';
    @Input() type: 'CLIENT_CSE' | 'CLIENT_APP' | 'CLIENT_PRO' = 'CLIENT_CSE';

    constructor(private authService: AuthService) {
    }

    ngOnInit(): void {
    }

    async getLogas() {
        const url: string = await this.authService
            .getLogas(this.id, this.type)
            .pipe(map(r => r.data))
            .toPromise();
        window.open(url, '_blank');
    }
}
