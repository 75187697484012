import {Component, Inject, OnInit} from '@angular/core';
import {Affiliate, Tpe} from '@mcv/core';
import {AffiliateTpeDataStore} from '@crm/app/store/store';
import {DataStore} from '@crm/app/store/services/store.service';
import {CrmTableComponent} from '@crm/interfaces/CrmTableComponent';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';
import {AffiliateTpeDetailComponent} from '@crm/app/affiliate/ui/affiliate-tpe-detail/affiliate-tpe-detail.component';

@Component({
    selector: 'mcv-tpe-list',
    templateUrl: './tpe-list.component.html',
    styleUrls: ['./tpe-list.component.scss']
})
export class TpeListComponent extends CrmTableComponent<Tpe> implements OnInit {

    affiliate: Affiliate;
    displayedColumns: string[] = [
        'id',
        'name',
        'state',
        'actions'
    ];

    constructor(@Inject(AffiliateTpeDataStore) public store: DataStore<Tpe>, private windowService: WindowManagerService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    openDetail(tpe: Tpe) {
        this.windowService.open(AffiliateTpeDetailComponent, {
            windowClass: 'large',
            title: `Détail TPE #${tpe.id}`,
            context: {affiliate_tpe_public_id: tpe.public_id}
        }
        );
    }
}
