import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASEURL } from '@mcv/config';
import { SingleResult } from '@mcv/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TrzToolsService {
    model = 'admin/trz-tools';

    constructor(
        @Inject(BASEURL) private baseUrl: string,
        private httpClient: HttpClient
    ) {}

    searchWallet(search: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/wallet`,
            search
        );
    }

    searchUser(search: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/user`,
            search
        );
    }

    searchTransfert(search: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/transfert`,
            search
        );
    }

    searchTransaction(search: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/transaction`,
            search
        );
    }

    searchBalance(search: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/balance`,
            search
        );
    }

    searchDocument(search: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/document`,
            search
        );
    }

    searchCard(search: any): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/card`,
            search
        );
    }

    listAnnualTurnover(): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/dictionnary/listAnnualTurnover`
        );
    }

    listLegalForm(): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/dictionnary/legalForm`
        );
    }

    listNumberEmployee(): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/dictionnary/listNumberEmployee`
        );
    }

    listNetIncome(): Observable<SingleResult<any>> {
        return this.httpClient.get<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/dictionnary/listNetIncome`
        );
    }

    kycreview(trzUserId: number): Observable<SingleResult<any>> {
        return this.httpClient.post<SingleResult<any>>(
            `${this.baseUrl}/${this.model}/kycreview`,
            { trzUserId }
        );
    }

    syncBalance(walletId: number): Observable<any> {
        return this.httpClient.post(
            `${this.baseUrl}/${this.model}/syncBalance`,
            { walletId }
        );
    }

    addMoney(cseId: number, amount: number): Observable<any> {
        return this.httpClient.post(`${this.baseUrl}/${this.model}/addMoney`, {
            cseId,
            amount
        });
    }
}
