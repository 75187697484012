import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';
import {Moment} from 'moment';

@Component({
    selector: 'mcv-admin-datepicker',
    templateUrl: './admin-datepicker.component.html',
    styleUrls: ['./admin-datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AdminDatepickerComponent),
            multi: true
        }
    ]
})
export class AdminDatepickerComponent implements OnInit, ControlValueAccessor {
    @Input() format: string;
    @Input() openOnFocus = false;
    value: any;
    disabled: boolean;

    constructor() {
    }

    onChange: any = (val: number) => {
    };

    onTouch: any = (val: number) => {
    };

    ngOnInit(): void {
    }

    setDate($event: MatDatepickerInputEvent<any, any | null>) {
        const date: Moment = $event.value;
        if (date && this.format) {
            this.onChange(date.format(this.format));
        } else if (date) {
            this.onChange(date.toDate());
        } else {
            this.onChange(null);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }

    focus(picker: MatDatepicker<any>) {
        if (this.openOnFocus) {
            picker.open();
        }
    }
}
