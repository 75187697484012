import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'mcv-formly-ng-field-checkbox',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <br/>
        <nb-checkbox [formControl]="formControl" [formlyAttributes]="field">
            {{to.placeholder}}
        </nb-checkbox>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
export class FormlyNgFieldCheckboxComponent extends FieldType {
}
