import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'mcv-formly-ng-field-country',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <mcv-country-select [formControl]="formControl" [formlyAttributes]="field"></mcv-country-select>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
export class FormlyNgFieldCountryComponent extends FieldType {
}
