<router-outlet></router-outlet>
<notifier-container></notifier-container>
<val-default-errors>
    <ng-template let-label valError="required">Ce champ est requis</ng-template>
    <ng-template let-label valError="email">L'adresse email saisie n'est pas valide</ng-template>
    <ng-template let-error="error" let-label valError="minlength">{{ label || 'Ce champ' }} doit comporter au
        moins {{ error.requiredLength }} caractères
    </ng-template>
    <ng-template let-error="error" let-label valError="min">
        {{ label || 'Ce champ' }} ne peut pas être inférieur à {{ error.min }}
    </ng-template>
    <ng-template let-error="error" let-label valError="max">
        {{ label || 'Ce champ' }} ne peut pas être supérieur à {{ error.max }}
    </ng-template>
    <ng-template let-error="error" let-label valError="pattern">{{ label || 'Ce champ' }} ne respecte pas les
        règles énoncées
    </ng-template>
    <ng-template let-error="error" let-label valError="notSame">Les valeurs ne correspondent pas</ng-template>
</val-default-errors>
