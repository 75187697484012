import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CseDocumentService} from '@mcv/coreservices';
import {Subscription} from 'rxjs';

@Component({
    selector: 'mcv-preview-doc',
    templateUrl: './preview-doc.component.html',
    styleUrls: ['./preview-doc.component.scss']
})
export class PreviewDocComponent implements OnInit, OnDestroy {
    @Input() srcUrl: any;
    @Input() documentId: number;
    subscription: Subscription = new Subscription();
    error: any;

    constructor(private cseDocumentService: CseDocumentService) {
    }

    async ngOnInit() {
        if (this.documentId) {
            try {
                const data: Blob = await this.cseDocumentService
                    .getPreviewData(this.documentId)
                    .toPromise();
                this.srcUrl = URL.createObjectURL(data);
            } catch (e) {
                this.error = 'Le document n\'a pas pu être chargé';
            }
        }
    }

    ngOnDestroy(): void {
        URL.revokeObjectURL(this.srcUrl);
    }

}
