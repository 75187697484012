<nb-form-field>
    <input (dateInput)="setDate($event)"
           (focus)="focus(picker)"
           [disabled]="disabled"
           [matDatepicker]="picker"
           [value]="value"
           fullWidth
           nbInput>
    <button (click)="picker.open()"
            [disabled]="disabled"
            ghost
            nbButton
            nbSuffix
            type="button">
        <nb-icon icon="calendar-alt" pack="far"></nb-icon>
    </button>
    <mat-datepicker #picker></mat-datepicker>
</nb-form-field>
