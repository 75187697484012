import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Type
} from '@angular/core';
import {WindowManagerOptions} from '@crm/app/window-manager/interfaces/WindowManagerOptions';
import {TaskBarComponent} from '@crm/app/window-manager/ui/task-bar/task-bar.component';
import {
    WindowManagerModalComponent
} from '@crm/app/window-manager/ui/window-manager-modal/window-manager-modal.component';

@Injectable({
    providedIn: 'root'
})
export class WindowManagerService {
    private _container: ComponentRef<any>;
    private taskBar: ComponentRef<TaskBarComponent>;
    private windowsOpen: Array<ComponentRef<WindowManagerModalComponent<any>>> = [];

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private applicationRef: ApplicationRef,
                private injector: Injector) {
    }

    createTaskBar(location: Element) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TaskBarComponent);
        this.taskBar = componentFactory.create(this.injector);
        const appRef: any = this.applicationRef;
        appRef.attachView(this.taskBar.hostView);
        location.appendChild(this.getComponentRootNode(this.taskBar));
    }

    open<T>(
        componentToInject: Type<T>,
        options: WindowManagerOptions = {},
        location: Element = this.getRootViewContainerNode()): WindowManagerModalComponent<T> {
        if (!this.taskBar) {
            this.createTaskBar(location);
        }
        return this.createContainer<T>(location, options, componentToInject);
    }

    createContainer<T>(location: Element, options, componentToInject: Type<any>) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(WindowManagerModalComponent);
        const componentRef = componentFactory.create(this.injector);
        const appRef: any = this.applicationRef;
        const componentRootNode = this.getComponentRootNode(componentRef);

        // project the options passed to the component instance
        this.projectComponentInputs(componentRef, options);
        componentRef.instance.component = componentToInject;
        appRef.attachView(componentRef.hostView);
        location.appendChild(componentRootNode);

        this.initContainerListeners(componentRootNode, location, componentRef);

        this.windowsOpen.unshift(componentRef);
        this.updatePosition();
        this.setActiveComponent(componentRef);

        window.setTimeout(() => {
            this.clearActiveExcept(componentRef);
        }, 0);

        return componentRef.instance as WindowManagerModalComponent<T>;
    }

    getRootViewContainer(): ComponentRef<any> {
        if (this._container) return this._container;

        const rootComponents = this.applicationRef['components'];
        if (rootComponents.length) return rootComponents[0];

        throw new Error('View Container not found! ngUpgrade needs to manually set this via setRootViewContainer.');
    }

    getComponentRootNode(componentRef: ComponentRef<any>): HTMLElement {
        return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    }

    getRootViewContainerNode(): HTMLElement {
        return this.getComponentRootNode(this.getRootViewContainer());
    }

    projectComponentInputs(component: ComponentRef<any>, options: any): ComponentRef<any> {
        if (options) {
            const props = Object.getOwnPropertyNames(options);
            for (const prop of props) {
                component.instance[prop] = options[prop];
            }
        }

        return component;
    }

    private setActiveComponent(componentRef: ComponentRef<any>) {
        componentRef.instance.active = true;
        this.clearActiveExcept(componentRef);
    }

    private updatePosition() {
        this.windowsOpen.forEach((c, i) => c.instance.position = i);
    }

    private initContainerListeners(componentRootNode, location: Element, componentRef: ComponentRef<any>) {
        const appRef: any = this.applicationRef;

        componentRef.instance.onClose.subscribe(_ => {
            componentRef.destroy();
        });

        componentRef.instance.onMinimize.subscribe(_ => {
            componentRef.instance.active = false;
            this.getComponentRootNode(this.taskBar)
                .appendChild(componentRootNode);
        });

        componentRef.instance.onRegular.subscribe(_ => {
            location.appendChild(componentRootNode);
            this.setActiveComponent(componentRef);
        });

        componentRef.instance.onFocus.subscribe(_ => {
            this.moveToFirstPosition(componentRef);
            this.setActiveComponent(componentRef);
        });

        componentRef.onDestroy(() => {
            appRef.detachView(componentRef.hostView);
            this.windowsOpen.splice(this.windowsOpen.indexOf(componentRef), 1);
        });
    }

    private moveToFirstPosition(componentRef: ComponentRef<any>) {
        this.windowsOpen.splice(this.windowsOpen.indexOf(componentRef), 1);
        this.windowsOpen.unshift(componentRef);
        this.updatePosition();
    }

    private clearActiveExcept(cpt: ComponentRef<any>) {
        this.windowsOpen.forEach(c => {
            c.instance.active = c === cpt;
        });
    }
}
