import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {CseEmploye} from '@mcv/core';
import {CseEmployeService} from '@mcv/coreservices';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-cse-employe-detail',
    templateUrl: './cse-employe-detail.component.html',
    styleUrls: ['./cse-employe-detail.component.scss']
})
export class CseEmployeDetailComponent implements OnInit {
    @Input() cse_employe_public_id: string;
    cseEmploye$: Observable<CseEmploye> = new Observable<CseEmploye>();

    constructor(private cseEmployeService: CseEmployeService,
                private fb: FormBuilder,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.readRouteParams();
        if (this.cse_employe_public_id) {
            this.load();
        }
    }

    private readRouteParams() {
        if (this.activatedRoute.snapshot.queryParamMap.has('cse_employe_public_id')) {
            this.cse_employe_public_id = this.activatedRoute.snapshot.queryParamMap.get('cse_employe_public_id');
        }
    }

    private load() {
        this.cseEmploye$ = this.cseEmployeService
            .view(this.cse_employe_public_id)
            .pipe(map(r => r.data));
    }
}
