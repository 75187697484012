import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'mcv-formy-ng-field-radio',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <br/>
        <nb-radio-group [formControl]="formControl" [formlyAttributes]="field" [class.d-flex]="to.inline">
            <nb-radio *ngFor="let option of to.options"
                      [value]="option.value">
                {{ option.label }}
            </nb-radio>
        </nb-radio-group>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
export class FormlyNgFieldRadioComponent extends FieldType {
}
