import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-task-bar',
    templateUrl: './task-bar.component.html',
    styleUrls: ['./task-bar.component.scss']
})
export class TaskBarComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
