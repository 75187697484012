import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

/**
 * Service Mock pour la production.
 * L'environnement de production ne possède pas (encore) de bus Redis, ce service sert à simuler le websocket service
 * Il ne renvoie rien
 */
@Injectable({
    providedIn: 'root'
})
export class SocketMockService {

    constructor() {
        console.log('WS Mock constructor');
    }

    on(eventName: string, callback: Function): void {
        return;
    }

    fromEvent<T>(eventName: string): Observable<T> {
        return new Subject<T>().asObservable();
    }

}
