import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TpeIndexComponent} from './pages/tpe/tpe-index.component';
import {TpeViewComponent} from './pages/tpe/tpe-view.component';
import {AffiliateViewComponent} from '@crm/app/affiliate/pages/affiliates/affiliate-view.component';
import {AffiliateIndexComponent} from '@crm/app/affiliate/pages/affiliates/affiliate-index.component';
import {UserIndexComponent} from '@crm/app/affiliate/pages/users/user-index.component';
import {UserViewComponent} from '@crm/app/affiliate/pages/users/user-view.component';
import {AffiliateViewInfoComponent} from '@crm/app/affiliate/ui/affiliate-view-info/affiliate-view-info.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/crm/affiliate/affiliates'
    },
    {
        path: 'affiliates',
        component: AffiliateIndexComponent
    },
    {
        path: 'affiliates/:id',
        component: AffiliateViewComponent,
        children: [
            {path: '', redirectTo: 'info', pathMatch: 'full'},
            {path: 'info', component: AffiliateViewInfoComponent},
            {path: 'users', component: AffiliateViewInfoComponent},
            {path: 'tpes', component: AffiliateViewInfoComponent}
        ]
    },
    {
        path: 'tpes',
        component: TpeIndexComponent
    },
    {
        path: 'tpes/:id',
        component: TpeViewComponent
    },
    {
        path: 'users',
        component: UserIndexComponent
    },
    {
        path: 'users/:id',
        component: UserViewComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AffiliateRoutingModule {
}
