<form (ngSubmit)="saveForm()" [formGroup]="affiliateForm">
    <formly-form [fields]="fields" [form]="affiliateForm" [model]="affiliateUser"></formly-form>
    <br/>
    <button [disabled]="saving || !affiliateForm.valid"
            [nbSpinner]="saving"
            fullWidth
            nbButton
            status="primary"
            type="submit">
        Enregistrer les modifications
    </button>
</form>
