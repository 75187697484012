import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SocketMockService} from '@crm/app/crm/services/socket-mock.service';
import {SocketService} from '@crm/app/crm/services/socket.service';
import {DataStore} from '@crm/app/store/services/store.service';
import {BaseRepository} from '@mcv/core';
import {mcvStore} from './store';

const genericFactory = <T>(repository: BaseRepository<T>, router: Router, activatedRoute: ActivatedRoute, socketService: SocketService): DataStore<T> => {
    return new DataStore<T>(repository, router, activatedRoute, socketService);
};
const genericDeps = [
    Router,
    ActivatedRoute,
    SocketService // en attendant le déploiement du redis en production
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    exports: [],
    providers: []
})
export class StoreModule {
    constructor(@Optional() @SkipSelf() parentModule?: StoreModule) {
        if (parentModule) {
            throw new Error(
                'StoreModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(config?: any): ModuleWithProviders<StoreModule> {
        return {
            ngModule: StoreModule,
            providers: [
                // {provide: SocketService, useClass: environment.production ? SocketMockService : SocketService},
                {provide: SocketService, useClass: SocketMockService},
                ...mcvStore.map(s => ({
                    provide: s.token,
                    useFactory: genericFactory,
                    deps: [...[s.repository], ...genericDeps]
                }))
            ]
        };
    }
}

