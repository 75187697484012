import {Component, Inject, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Affiliate} from '@mcv/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AffiliateDataStore} from '@crm/app/store/store';
import {DataStore} from '@crm/app/store/services/store.service';
import {filter, tap} from 'rxjs/operators';
import {NbTabComponent} from '@nebular/theme';

@Component({
    selector: 'mcv-affiliate-view',
    templateUrl: './affiliate-view.component.html',
    styleUrls: ['./affiliate-view.component.scss']
})
export class AffiliateViewComponent implements OnInit {

    subscription: Subscription = new Subscription();
    affiliate$: Observable<Affiliate[]>;
    loading$: Observable<boolean>;
    searchForm: FormGroup = this.fb.group({
        id: null,
        withAdmin: 1
    });
    activeRoute: string;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: FormBuilder,
                @Inject(AffiliateDataStore) public store: DataStore<Affiliate>) {
        this.loadParam();
    }

    ngOnInit(): void {
    }

    loadParam() {
        this.activatedRoute
            .paramMap
            .pipe(
                filter(p => p.has('id')),
                tap(p => this.searchForm.patchValue({id: p.get('id')}))
            )
            .subscribe(_ => {
                this.activeRoute = this.activatedRoute.snapshot.firstChild.routeConfig.path;
                this.loading$ = this.store.loading$;
                this.affiliate$ = this.store.data$.asObservable();
                this.store.reflectUrl = false;
                this.store.linkFilterForm(this.searchForm);
                this.store.readDataList();
            });
    }

    changeTab($event: NbTabComponent, affiliateId: number) {
        if (this.activeRoute && this.activeRoute !== $event.tabId) {
            this.activeRoute = $event.tabId;
            this.router.navigate(['/crm/affiliate/affiliates/', affiliateId, $event.tabId], {queryParamsHandling: 'merge'});
        }
    }
}
