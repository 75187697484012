import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, MerchantCategory} from '@mcv/core';

@Injectable({
    providedIn: 'root'
})
export class MerchantCategoryService extends BaseRepository<MerchantCategory> {
    protected model = 'merchant-category';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }
}
