import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {AbstractControl} from '@angular/forms';

@Component({
    selector: 'mcv-formly-ng-field-toggle',
    template: `
        <label class="mb-2 mt-2 mr-2" [class.required]="to.required" (click)="changeToggle(formControl)">
            {{to.label || (formControl.value ? to.onlabel : to.offlabel)}}
        </label>
        <nb-toggle [formControl]="formControl" [formlyAttributes]="field">
            {{to.placeholder}}
        </nb-toggle>
        <val-errors controlName="{{key}}"></val-errors>
    `,
    styles: [':host(mcv-formly-ng-field-toggle) {display:flex;align-items: center}']
})
export class FormlyNgFieldToggleComponent extends FieldType {


    changeToggle(formControl: AbstractControl) {
        formControl.patchValue(!formControl.value);
    }
}
