<table [dataSource]="store"
       cdk-table
       class="table table-borderless table-bigrow table-bigrow-hover"
       matSort
       matSortActive="name"
       matSortDirection="asc"
       matSortDisableClear
       multiTemplateDataRows>


    <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
    <tr *cdkRowDef="let row; columns: displayedColumns;" [routerLink]="['/crm/affiliate/affiliates', row.id]" cdk-row
        style="cursor: pointer;"></tr>

    <tr *matNoDataRow class="mat-row">
        <td class="mat-cell text-center" colspan="7">
            Pas d’élément correspondant à votre recherche
        </td>
    </tr>

    <ng-container cdkColumnDef="id">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="id">#</th>
        <td *cdkCellDef="let element" cdk-cell class="table__id--width table__id--bold">
            #{{element.id}}
        </td>
    </ng-container>
    <ng-container cdkColumnDef="legalName">
        <th *cdkHeaderCellDef cdk-header-cell mat-sort-header="legalName">Nom</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width {{store.data[store.data.indexOf(element)] | wsClass:'legalName'}}"
            style="word-break: keep-all">
            <mcv-affiliate-info *ngIf="element" [affiliate]="element"></mcv-affiliate-info>
        </td>
    </ng-container>
    <ng-container cdkColumnDef="contact">
        <th *cdkHeaderCellDef cdk-header-cell>Contact</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__ordername--width {{store.data[store.data.indexOf(element)]}}"
            style="word-break: keep-all">
            <mcv-affiliate-user-avatar *ngIf="element.affiliate_user && element.affiliate_user.length > 0"
                                       [affiliateUser]="element.affiliate_user[0]"
                                       [displayEmail]="true"
                                       [displayId]="true"
                                       [displayMobile]="true"
                                       [displayPhone]="true"></mcv-affiliate-user-avatar>
        </td>
    </ng-container>
    <ng-container cdkColumnDef="state">
        <th *cdkHeaderCellDef cdk-header-cell>Info</th>
        <td *cdkCellDef="let element" cdk-cell
            class="table__state--width {{store.data[store.data.indexOf(element)] | wsClass:'state'}}"
            style="word-break: keep-all">
            <div class="d-flex align-items-center">
                <div class="mr-1">Status :</div>
                <mcv-badge [badgeClass]="element.state | affiliateStateClass">{{element.state}}</mcv-badge>
            </div>
        </td>
    </ng-container>

    <ng-container cdkColumnDef="actions">
        <th *cdkHeaderCellDef cdk-header-cell>Actions</th>
        <td *cdkCellDef="let element" cdk-cell class="cell--action__width">

            <button [queryParams]="{ withAffiliateId: element.id }"
                    [routerLink]="['/crm/affiliate/affiliates/'+element.id+'/users']"
                    class="mr-2"
                    nbButton
                    nbTooltip="Afficher utilisateurs"
                    size="small"
                    status="primary">
                <i class="far fa-user fa-fw"></i>
            </button>

            <button [queryParams]="{ withAffiliateId: element.id }"
                    [routerLink]="['/crm/affiliate/affiliates/'+element.id+'/tpes']"
                    class="mr-2"
                    nbButton
                    nbTooltip="Afficher TPEs"
                    size="small"
                    status="primary">
                <i class="far fa-cash-register fa-fw"></i>
            </button>

            <mcv-button-logas
                *ngIf="element.affiliate_user && element.affiliate_user.length>0"
                [id]="element.affiliate_user[0].id"
                [name]="element.legalName"
                [size]="'small'"
                [type]="'CLIENT_PRO'"></mcv-button-logas>
        </td>
    </ng-container>
</table>
<br/>
<ng-container *ngIf="paging$ | async as pagingInfo">
    <mat-paginator (page)="changePage($event)"
                   *ngIf="pagingInfo.count > limit"
                   [color]="'primary'"
                   [length]="pagingInfo.count"
                   [pageIndex]="pagingInfo.current_page - 1"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="limit">
    </mat-paginator>
</ng-container>
<mat-menu #menu="matMenu">
    <ng-template let-element="element" matMenuContent>

    </ng-template>
</mat-menu>
