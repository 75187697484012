<div #windowmanagerWrapper
     [cdkDragDisabled]="state === WindowState.minimize"
     [class.active]="active"
     [east]="true"
     [mcvResizable]="state !== WindowState.minimize"
     [northEast]="true"
     [northWest]="true"
     [north]="true"
     [southEast]="true"
     [southWest]="true"
     [south]="true"
     [west]="true"
     cdkDrag
     class="windowmanager-wrapper {{state}} {{wrapperClass}} {{windowClass}} mat-elevation-z{{24-(position*2)}}">
    <div cdkDragHandle class="windowmanager-header {{headerClass}}">
        <div class="header--title__flex">{{title}}</div>
        <div class="button-wrapper">
            <button (click)="minimize()" *ngIf="state!=WindowState.minimize"
                    class="windowmanager-button {{buttonClass}}">
                <i class="fas fa-window-minimize fa-fw"></i>
            </button>
            <button (click)="regular()" *ngIf="state!=WindowState.regular"
                    class="windowmanager-button {{buttonClass}}">
                <i class="fas fa-window-restore fa-fw"></i>
            </button>
            <button (click)="close()"
                    class="windowmanager-button {{buttonClass}}">
                <i class="fas fa-times fa-fw"></i>
            </button>
        </div>
    </div>
    <div #bodymodal class="windowmanager-body {{bodyClass}}" tabindex="-1">
        <ng-template #anchor></ng-template>
    </div>
</div>
