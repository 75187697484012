import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Affiliate} from '@mcv/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AffiliateService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';
import {fromDates, toDates} from '@crm/app/utils/ConvertDate';
import {FormlyFieldConfig} from '@ngx-formly/core';

@Component({
    selector: 'mcv-affiliate-view-info',
    templateUrl: './affiliate-view-info.component.html',
    styleUrls: ['./affiliate-view-info.component.scss']
})
export class AffiliateViewInfoComponent implements OnInit, OnChanges {
    @Input() affiliate: Affiliate;
    affiliateForm: FormGroup = this.fb.group({});
    saving = false;

    fields: FormlyFieldConfig[] = [
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'État'},
            fieldGroup: [
                {
                    key: 'state',
                    className: 'col-6',
                    type: 'select',
                    templateOptions: {
                        label: 'Status',
                        placeholder: '',
                        required: true,
                        options: [
                            {label: 'new', value: 'new'},
                            {label: 'draft', value: 'draft'},
                            {label: 'draft_wait', value: 'draft_wait'},
                            {label: 'draft_kyc', value: 'draft_kyc'},
                            {label: 'draft_kyc_done', value: 'draft_kyc_done'},
                            {label: 'draft_wallet', value: 'draft_wallet'},
                            {label: 'sync', value: 'sync'},
                            {label: 'sync_toclose', value: 'sync_toclose'},
                            {label: 'sync_toclose', value: 'closed'}
                        ]
                    }
                }
            ]
        },
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Legal'},
            fieldGroup: [
                {
                    key: 'legalName',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Dénomination',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'legalRegistrationNumber',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'SIRET',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'legalTvaNumber',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Tva',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'legalRegistrationDate',
                    className: 'col-6',
                    type: 'date',
                    templateOptions: {
                        label: 'Date d\'immatriculation RCS',
                        placeholder: '',
                        required: true,
                        type: 'YYYY-MM-DD'
                    }
                },
                {
                    key: 'legalForm',
                    className: 'col-6',
                    type: 'legalFormSelect',
                    defaultValue: 'legalForm',
                    templateOptions: {
                        label: 'Forme juridique',
                        placeholder: '',
                        required: false
                    }
                },
                {
                    key: 'legalShareCapital',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'LegalShareCapital',
                        placeholder: '',
                        required: false
                    }
                },
                {
                    key: 'legalSector',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Activité (Code NAF ou APE)',
                        placeholder: '',
                        required: false
                    }
                },
                {
                    key: 'legalAnnualTurnOver',
                    className: 'col-6',
                    type: 'annualTurnOverSelect',
                    defaultValue: 'legalAnnualTurnOver',
                    templateOptions: {
                        label: 'Tranche de CA annuel (en k€)',
                        placeholder: '',
                        required: false
                    }
                },
                {
                    key: 'legalNetIncomeRange',
                    className: 'col-6',
                    type: 'legalNetIncomeRangeSelectComponent',
                    templateOptions: {
                        label: 'Tranche de bénéfice annuel (en k€)',
                        placeholder: '',
                        required: false
                    }
                },
                {
                    key: 'legalNumberOfEmployeeRange',
                    className: 'col-6',
                    type: 'legalNumberOfEmployeeRangeSelectComponent',
                    defaultValue: 'legalNumberOfEmployeeRange',
                    templateOptions: {
                        label: 'Tranche d\'effectif',
                        placeholder: '',
                        required: false
                    }
                },
                {
                    key: 'effectiveBeneficiary',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'EffectiveBeneficiary',
                        placeholder: '',
                        required: false
                    }
                }
            ]
        },
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Adresse'},
            fieldGroup: [
                {
                    key: 'address1',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Adresse',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'address2',
                    className: 'col-6',
                    type: 'input',
                    defaultValue: null,
                    templateOptions: {
                        label: 'Adresse 2',
                        placeholder: '',
                        required: false,
                        maxLength: 50
                    }
                },
                {
                    key: 'postcode',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Code postal',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'city',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Ville',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'country',
                    className: 'col-6',
                    type: 'country',
                    templateOptions: {
                        label: 'Pays',
                        placeholder: '',
                        required: true
                    }
                }
            ]
        },
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Contact'},
            fieldGroup: [
                {
                    key: 'email',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Adresse e-mail',
                        placeholder: '',
                        type: 'email',
                        required: false
                    }
                },
                {
                    key: 'phone',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Téléphone fixe',
                        placeholder: '',
                        required: false
                    }
                },
                {
                    key: 'mobile',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Téléphone mobile',
                        placeholder: '',
                        required: false
                    }
                }
            ]
        },
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Contrat'},
            fieldGroup: [
                {
                    key: 'contrat_cadre_accepted',
                    className: 'col-6',
                    type: 'checkbox',
                    templateOptions: {
                        label: 'Contrat accepté',
                        placeholder: 'Le contrat a été accepté?',
                        required: true
                    }
                },
                {
                    key: 'contrat_cadre_version',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'version',
                        placeholder: '',
                        required: false
                    }
                }
            ]
        },
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Autre'},
            fieldGroup: [
                {
                    key: 'blocked',
                    className: 'col-6',
                    type: 'checkbox',
                    templateOptions: {
                        label: 'Bloquée',
                        placeholder: 'Le compte est bloqué?',
                        required: true
                    }
                },
                {
                    key: 'pricing_commission',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Commission',
                        placeholder: '',
                        required: false,
                        type: 'number'
                    }
                }
            ]
        }
    ];

    constructor(private affiliateService: AffiliateService,
                private notifierService: McvnotifierService,
                private fb: FormBuilder) {
    }

    async ngOnInit(): Promise<void> {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.affiliate) {
            fromDates(changes.affiliate.currentValue, ['legalRegistrationDate']);
            this.affiliateForm.patchValue(changes.affiliate.currentValue);
        }
    }

    async saveForm() {
        try {
            this.saving = true;
            const formData = this.affiliateForm.value;
            toDates(formData, ['legalRegistrationDate']);
            await this.affiliateService.edit(this.affiliate.public_id, formData)
                .toPromise();
            this.notifierService.success('Affilié mis à jour');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }

}
