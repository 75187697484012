import {format, isDate, parse} from 'date-fns';

export const fromDates = (object: any, fields: string[], formFormat = 'yyyy-MM-dd') => {
    fields.forEach(f => {
        if (object[f]) {
            object[f] = parse(object[f], formFormat, new Date());
        }
    });
};

export const toDates = (object: any, fields: string[], toFormat = 'yyyy-MM-dd') => {
    fields.forEach(f => {
        if (object[f] && isDate(object[f])) {
            object[f] = format(object[f], toFormat);
        }
    });
};
