import {NgxMatDatetimePicker} from '@angular-material-components/datetime-picker';
import {MatDatetimePickerInputEvent} from '@angular-material-components/datetime-picker/lib/datetime-input';
import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl} from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'mcv-admin-datetime-picker',
    templateUrl: './admin-datetime-picker.component.html',
    styleUrls: ['./admin-datetime-picker.component.scss']
})
export class AdminDatetimePickerComponent implements OnInit, ControlValueAccessor {
    @Input() format: string;
    @Input() openOnFocus = false;
    dateControl: FormControl;
    disabled: boolean;

    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    onChange: any = (val: number) => {
    };

    onTouch: any = (val: number) => {
    };

    ngOnInit(): void {
    }

    setDate($event: MatDatetimePickerInputEvent<any>) {
        this.setInternalDate($event.value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: Date | string): void {
        if (obj) {
            obj = this.setInternalDate(obj);
            this.dateControl = new FormControl(obj);
        }
    }

    focus(picker: NgxMatDatetimePicker<any>) {
        if (this.openOnFocus) {
            picker.open();
        }
    }

    private setInternalDate(dateInput: Date | string) {
        const date = moment(dateInput);
        let newData = null;
        if (date && this.format) {
            newData = (date.utc(false)
                .format(this.format));
        } else if (date) {
            newData = (date.utc(false)
                .toDate());
        }
        this.onChange(newData);
        return newData;
    }
}
