import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Tpe} from '@mcv/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {AffiliateTpeService} from '@mcv/coreservices';
import {McvnotifierService} from '@mcv/ui';

@Component({
    selector: 'mcv-affiliate-tpe-view',
    templateUrl: './affiliate-tpe-view.component.html',
    styleUrls: ['./affiliate-tpe-view.component.scss']
})
export class AffiliateTpeViewComponent implements OnInit, OnChanges {
    @Input() affiliateTpe: Tpe;
    affiliateForm: FormGroup = this.fb.group({});
    saving = false;

    fields: FormlyFieldConfig[] = [
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'État'},
            fieldGroup: [
                {
                    key: 'state',
                    className: 'col-6',
                    type: 'select',
                    templateOptions: {
                        label: 'Status',
                        placeholder: '',
                        required: true,
                        options: [
                            {label: 'new', value: 'new'},
                            {label: 'ok', value: 'ok'},
                            {label: 'refused', value: 'refused'},
                            {label: 'deleted', value: 'deleted'}
                        ]
                    }
                },
                {
                    key: 'idx',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Index (tri)',
                        placeholder: '',
                        required: true
                    }
                }
            ]
        },
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Identifiant'},
            fieldGroup: [
                {
                    key: 'name',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Nom',
                        placeholder: '',
                        required: true
                    }
                },
                {
                    key: 'merchantId',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Merchant id',
                        placeholder: '',
                        required: false
                    }
                },
                {
                    key: 'terminalId',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Terminal id',
                        placeholder: '',
                        required: false
                    }
                },
                {
                    key: 'mccCode',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'MCC code',
                        placeholder: '',
                        required: false
                    }
                }
            ]
        },
        {
            fieldGroupClassName: 'row',
            wrappers: ['fieldset'],
            templateOptions: {label: 'Montant'},
            fieldGroup: [
                {
                    key: 'total_ca',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Total CA (EURO)',
                        placeholder: ''
                    }
                },
                {
                    key: 'total_transactions',
                    className: 'col-6',
                    type: 'input',
                    templateOptions: {
                        label: 'Total transactions',
                        placeholder: ''
                    }
                }
            ]
        }
    ];

    constructor(private affiliateTpeService: AffiliateTpeService,
                private notifierService: McvnotifierService,
                private fb: FormBuilder) {
    }

    async ngOnInit(): Promise<void> {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.affiliate) {
            this.affiliateForm.patchValue(changes.affiliate.currentValue);
        }
    }

    async saveForm() {
        try {
            this.saving = true;
            const formData = this.affiliateForm.value;
            await this.affiliateTpeService.edit(this.affiliateTpe.public_id, formData)
                .toPromise();
            this.notifierService.success('Tpe mis à jour');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }
}
