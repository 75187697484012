import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SocketService } from '@crm/app/crm/services/socket.service';
import { MessageBus } from '@crm/app/store/interfaces/MessageBus';
import { buildHttpParamsFilter, buildSortHttpParams } from '@crm/app/utils/HttpparamsBuilder';
import { environment } from '@crm/environments/environment';
import { BaseRepository, MetaPaging } from '@mcv/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class DataStore<T> extends DataSource<T> {
    public sorting: { active: string, direction: string };
    public data: T[] = [];
    public data$: Subject<T[]> = new Subject<T[]>();
    public paging$: BehaviorSubject<MetaPaging> = new BehaviorSubject<MetaPaging>(null);
    public reflectUrl = false;
    protected loadingSubject$ = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject$
        .asObservable()
        .pipe(
            debounceTime(300),
            distinctUntilChanged()
        );
    protected httpParams: HttpParams = new HttpParams();
    protected paging: MetaPaging = {
        count: null,
        current_page: 1,
        has_next_page: null,
        has_prev_page: null,
        limit: 10,
        page_count: null
    };
    protected filterForm: FormGroup;
    protected filter: any;
    protected entities: Map<number, T> = new Map();
    protected subscription: Subscription = new Subscription();

    constructor(protected repository: BaseRepository<T>,
                protected router: Router,
                protected activatedRoute: ActivatedRoute,
                protected socketService: SocketService) {
        super();
        if (!environment.production) {
            this.listenSocket();
        }
    }

    setInitialPaging(page: number, limit: number) {
        this.paging.current_page = page;
        this.paging.limit = limit;
    }

    listenSocket() {
        this.socketService
            .fromEvent(`${this.repository.getModel()}/afterSave`)
            .pipe(
                delay(500),
                filter((data: MessageBus) => this.mustRefresh(data)),
                mergeMap(message => forkJoin([of(message), this.getListFromRepository()])),
                map(([message, data]) => {
                    if (!message.body.new) {
                        this.highlightChanges(data, message);
                    }
                    return data;
                })
            )
            .subscribe(d => {
                this.data = [...d];
                this.data$.next(this.data);
            });

        this.socketService
            .fromEvent(`${this.repository.getModel()}/afterDelete`)
            .pipe(
                delay(500),
                mergeMap(message => forkJoin([of(message), this.getListFromRepository()])),
                map(([message, data]) => {
                    return data;
                })
            )
            .subscribe(d => {
                this.data = [...d];
                this.data$.next(this.data);
            });
    }

    mustRefresh(message: MessageBus): boolean {
        if (message.body.new || !message.targetId) {
            return true;
        }
        return !!(message.targetId && this.data.find(d => d['id'] === message.targetId));
    }

    connect(collectionViewer: CollectionViewer): Observable<T[] | ReadonlyArray<T>> {
        return this.data$.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.paging.current_page = 1;
        this.data$.next([]);
    }

    refreshUrl() {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { ...this.filter, ...this.paging },
            queryParamsHandling: 'merge',
            replaceUrl: true
        });
    }

    linkFilterForm(filterForm: FormGroup) {
        this.filterForm = filterForm;
        this.filter = filterForm.value;
        this.subscription.add(filterForm.valueChanges
            .pipe(
                debounceTime(250)
            )
            .subscribe((v: any) => {
                this.filter = v;
                this.paging.current_page = 1;
                this.paging$.next(this.paging);
                this.loadData();
            }));
    }

    listenUrl() {
    }

    readFromUrl() {
        const queryParams: any = this.activatedRoute.snapshot.queryParams;
        let pageChange = false;
        let filterChange = false;
        for (const key in queryParams) {
            if (queryParams.hasOwnProperty(key)) {
                const control = this.filterForm ? this.filterForm.get(key) : null;
                const value = queryParams[key];
                if (control) {
                    control.patchValue(value, { emitEvent: false });
                    filterChange = true;
                }
                if (this.paging[key]) {
                    this.paging[key] = +value;
                    pageChange = true;
                }
            }
        }
        if (pageChange) {
            this.paging$.next(this.paging);
        }
        if (filterChange) {
            this.filter = this.filterForm.value;
        }
    }

    changeSort(sort: { active: string, direction: string }) {
        this.sorting = sort;
        this.loadData();
    }

    changePage(pageEvent: PageEvent) {
        this.paging.limit = pageEvent.pageSize;
        this.paging.current_page = pageEvent.pageIndex + 1;
        this.loadData();
    }

    readDataList() {
        this.loadData();
    }

    private highlightChanges(data, message) {
        for (const t in data) {
            if (data.hasOwnProperty(t) && data[t].hasOwnProperty('id')) {
                if (data[t]['id'] === message.targetId) {
                    this.compareBeforeAfter(data[t], this.data.find(d => d['id'] === message.targetId));
                    break;
                }
            }
        }
    }

    private compareBeforeAfter(newValue: T, oldValue: T) {
        for (const prop in newValue) {
            if (oldValue.hasOwnProperty(prop) &&
                newValue.hasOwnProperty(prop) &&
                newValue[prop] !== oldValue[prop]) {
                if (!newValue.hasOwnProperty('_ws_class')) {
                    newValue['_ws_class'] = {};
                }

                const value = newValue[prop];
                // @ts-ignore
                if (value && !isNaN(value)) {
                    newValue['_ws_class'][prop] = value < oldValue[prop] ? 'blink-danger' : 'blink-success';
                } else {
                    newValue['_ws_class'][prop] = 'blink-primary';
                }
            }
        }
    }

    private async loadData() {
        this.data = [...await this.getListFromRepository()
            .toPromise()];
        if (this.reflectUrl) {
            this.refreshUrl();
        }
        this.data$.next(this.data);
    }

    private getListFromRepository(): Observable<T[]> {
        this.loadingSubject$.next(true);
        const page = this.paging.current_page;
        const limit = this.paging.limit;
        const sortDirection = this.sorting?.direction;
        const sortField = this.sorting?.active;
        let params: HttpParams = (!!sortDirection && !!sortField) ? buildSortHttpParams(this.httpParams, sortDirection, sortField) : this.httpParams;
        if (this.filter) {
            params = buildHttpParamsFilter(params, this.filter);
        }
        return this.repository
            .getList(page, limit, params)
            .pipe(
                tap(r => this.paging$.next(r.meta)),
                tap(_ => this.loadingSubject$.next(false)),
                map(r => r.data),
                catchError(err => {
                    this.loadingSubject$.next(false);
                    return of([]);
                })
            );
    }
}
