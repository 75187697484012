import {Component, Inject, Input, OnInit} from '@angular/core';
import {Affiliate, AffiliateUser} from '@mcv/core';
import {AffiliateUserDataStore} from '@crm/app/store/store';
import {DataStore} from '@crm/app/store/services/store.service';
import {CrmTableComponent} from '@crm/interfaces/CrmTableComponent';
import {
    AffiliateUserDetailComponent
} from '@crm/app/affiliate/ui/affiliate-user-detail/affiliate-user-detail.component';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';

@Component({
    selector: 'mcv-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends CrmTableComponent<AffiliateUser> implements OnInit {
    @Input() affiliate: Affiliate;

    displayedColumns: string[] = [
        'id',
        'name',
        'state',
        'actions'
    ];

    constructor(@Inject(AffiliateUserDataStore) public store: DataStore<AffiliateUser>,
                private windowService: WindowManagerService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    openDetail(user: AffiliateUser) {
        this.windowService.open(AffiliateUserDetailComponent, {
            windowClass: 'large',
            title: `Détail utilisateur #${user.id}`,
            context: {affiliate_user_public_id: user.public_id}
        }
        );
    }
}
