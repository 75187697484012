import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AffiliateStateClassPipe } from '@crm/app/shared/pipes/affiliate-state-class.pipe';
import { CseDocumentListComponent } from '@crm/app/shared/ui/cse-document-list/cse-document-list.component';
import { CseSelectComponent } from '@crm/app/shared/ui/cse-select/cse-select.component';
import { FormlyCategorySelectComponent } from '@crm/app/shared/ui/formly/formly-category-select.component';
import { FormlyCseStateSelectComponent } from '@crm/app/shared/ui/formly/formly-cse-state-select';
import { FormlyCseemployeStateSelectComponent } from '@crm/app/shared/ui/formly/formly-cseemploye-state-select';
import { FormlyCurrencyInputComponent } from '@crm/app/shared/ui/formly/formly-currency-input';
import { FormlyDocumentTypeSelectComponent } from '@crm/app/shared/ui/formly/formly-document-type-select.component';
import { FormlyFileChooserComponent } from '@crm/app/shared/ui/formly/formly-file-chooser.component';
import { FormlyIbanInputComponent } from '@crm/app/shared/ui/formly/formly-iban-input.component';
import { FormlyIconInputComponent } from '@crm/app/shared/ui/formly/formly-icon-input.component';
import {
    FormlyLegalAnnualTurnOverSelectComponent
} from '@crm/app/shared/ui/formly/formly-legal-annual-turn-over-select.component';
import { FormlyLegalFormSelectComponent } from '@crm/app/shared/ui/formly/formly-legal-form-select.component';
import {
    FormlyLegalNetIncomeRangeSelectComponent
} from '@crm/app/shared/ui/formly/formly-legal-net-income-range-select.component';
import {
    FormlyLegalNumberOfEmployeeRangeSelectComponent
} from '@crm/app/shared/ui/formly/formly-legal-number-of-employee-range-select.component';
import { FormlyNgFieldCheckboxComponent } from '@crm/app/shared/ui/formly/formly-ng-field-checkbox.component';
import { FormlyNgFieldCountryComponent } from '@crm/app/shared/ui/formly/formly-ng-field-country.component';
import { FormlyNgFieldDateComponent } from '@crm/app/shared/ui/formly/formly-ng-field-date.component';
import { FormlyNgFieldDateTimeComponent } from '@crm/app/shared/ui/formly/formly-ng-field-datetime-component';
import { FormlyNgFieldRadioComponent } from '@crm/app/shared/ui/formly/formly-ng-field-radio.component';
import { FormlyNgFieldSelectComponent } from '@crm/app/shared/ui/formly/formly-ng-field-select.component';
import { FormlyNgFieldToggleComponent } from '@crm/app/shared/ui/formly/formly-ng-field-toggle.component';
import { FormlyPhoneNumberComponent } from '@crm/app/shared/ui/formly/formly-phone-number.component';
import { FormlyTextareaComponent } from '@crm/app/shared/ui/formly/formly-textarea.component';
import { KycDocumentUploadComponent } from '@crm/app/shared/ui/kyc-document-upload/kyc-document-upload.component';
import { TransactionFilterComponent } from '@crm/app/shared/ui/transaction-filter/transaction-filter.component';
import { TrzViewToolsComponent } from '@crm/app/shared/ui/trz-view-tools/trz-view-tools.component';
import { WalletCardComponent } from '@crm/app/shared/ui/wallet-card/wallet-card.component';
import { CoreservicesModule } from '@mcv/coreservices';
import { NgxTimelineAlbeModule } from '@mcv/ngx-timeline';
import { UiModule } from '@mcv/ui';
import {
    NbAccordionModule,
    NbAlertModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbDatepickerModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbSpinnerModule,
    NbToggleModule,
    NbTooltipModule
} from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { NgxFileHelpersModule } from 'ngx-file-helpers';
import { NgxIbanModule } from 'ngx-iban';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { BooleanPipe } from './pipes/boolean.pipe';
import { CseDocumentStatutPipe } from './pipes/cse-document-statut.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { FirstPipe } from './pipes/first.pipe';
import { LoglevelClassPipe } from './pipes/loglevel-class.pipe';
import { WalletFetcherPipe } from './pipes/wallet-fetcher.pipe';
import { WalletStateClassPipe } from './pipes/wallet-state-class.pipe';
import { WsClassPipe } from './pipes/ws-class.pipe';
import { AdminDatepickerComponent } from './ui/admin-datepicker/admin-datepicker.component';
import { AdminDatetimePickerComponent } from './ui/admin-datetime-picker/admin-datetime-picker.component';
import { AffiliateInfoComponent } from './ui/affiliate-info/affiliate-info.component';
import { AffiliateUserAvatarComponent } from './ui/affiliate-user-avatar/affiliate-user-avatar.component';
import { ButtonFileChooserComponent } from './ui/button-file-chooser/button-file-chooser.component';
import { ButtonHistoryBackComponent } from './ui/button-history-back/button-history-back.component';
import { ButtonKycreviewComponent } from './ui/button-kycreview/button-kycreview.component';
import { ButtonLogasComponent } from './ui/button-logas/button-logas.component';
import { ButtonTrzBalanceComponent } from './ui/button-trz-balance/button-trz-balance.component';
import { ButtonTrzDocumentComponent } from './ui/button-trz-document/button-trz-document.component';
import { ButtonTrzTransactionComponent } from './ui/button-trz-transaction/button-trz-transaction.component';
import { ButtonTrzUserComponent } from './ui/button-trz-user/button-trz-user.component';
import { ButtonTrzWalletComponent } from './ui/button-trz-wallet/button-trz-wallet.component';

import { CategorySelectComponent } from './ui/category-select/category-select.component';
import { CseAvatarComponent } from './ui/cse-avatar/cse-avatar.component';
import { CseDocumentSynthesisComponent } from './ui/cse-document-synthesis/cse-document-synthesis.component';
import { CseEmployeAvatarComponent } from './ui/cse-employe-avatar/cse-employe-avatar.component';
import {
    CseEmployeCardStateUserSelectComponent
} from './ui/cse-employe-card-state-user-select/cse-employe-card-state-user-select.component';
import { CseStateSelectComponent } from './ui/cse-state-select/cse-state-select.component';
import { CseUserAvatarComponent } from './ui/cse-user-avatar/cse-user-avatar.component';
import { CseemployeStateSelectComponent } from './ui/cseemploye-statut-select/cseemploye-state-select.component';
import { DocumentTypeSelectComponent } from './ui/document-type-select/document-type-select.component';
import { FormlyNgFieldInputComponent } from './ui/formly/formly-ng-field-input.component';
import { FormlyNgWrapperFieldsetComponent } from './ui/formly/formly-ng-wrapper-fieldset.component';
import { IconInputComponent } from './ui/icon-input/icon-input.component';
import {
    LegalAnnualTurnOverSelectComponent
} from './ui/legal-annual-turn-over-select/legal-annual-turn-over-select.component';
import { LegalFormSelectComponent } from './ui/legal-form-select/legal-form-select.component';
import {
    LegalNetIncomeRangeSelectComponent
} from './ui/legal-net-income-range-select/legal-net-income-range-select.component';
import {
    LegalNumberOfEmployeeRangeSelectComponent
} from './ui/legal-number-of-employee-range-select/legal-number-of-employee-range-select.component';
import { ModalConfirmationComponent } from './ui/modal-confirmation/modal-confirmation.component';
import { PreviewDocComponent } from './ui/preview-doc/preview-doc.component';
import { TrzCardAvatarComponent } from './ui/trz-card-avatar/trz-card-avatar.component';
import { TrzWalletAvatarComponent } from './ui/trz-wallet-avatar/trz-wallet-avatar.component';
import { WalletAvatarComponent } from './ui/wallet-avatar/wallet-avatar.component';
import { WalletTransactionListComponent } from './ui/wallet-transaction-list/wallet-transaction-list.component';
import { CseDocumentTreezorCommentPipe } from './pipes/cse-document-treezor-comment.pipe';
import { CseTreezorCommentPipe } from './pipes/cse-treezor-comment.pipe';
import { CseUserTreezorCommentPipe } from './pipes/cse-user-treezor-comment.pipe';
import { FormlyAmountInputComponent } from '@crm/app/shared/ui/formly/formly-amount-input.component';
import { NgxCurrencyModule } from 'ngx-currency';
import {
    AdminCseEmployeRemoveReturnComponent
} from '@crm/app/shared/ui/admin-cse-employe-remove-return/admin-cse-employe-remove-return.component';

@NgModule({
    declarations: [
        AdminCseEmployeRemoveReturnComponent,
        WsClassPipe,
        CseEmployeAvatarComponent,
        LoglevelClassPipe,
        TrzWalletAvatarComponent,
        CseUserAvatarComponent,
        CseAvatarComponent,
        ButtonTrzUserComponent,
        ButtonTrzWalletComponent,
        ButtonTrzBalanceComponent,
        ButtonTrzTransactionComponent,
        ButtonLogasComponent,
        LegalFormSelectComponent,
        LegalAnnualTurnOverSelectComponent,
        FirstPipe,
        AffiliateUserAvatarComponent,
        AffiliateInfoComponent,
        LegalNetIncomeRangeSelectComponent,
        LegalNumberOfEmployeeRangeSelectComponent,
        ButtonHistoryBackComponent,
        FormlyNgFieldInputComponent,
        FormlyNgWrapperFieldsetComponent,
        FormlyNgFieldSelectComponent,
        FormlyNgFieldCheckboxComponent,
        FormlyNgFieldToggleComponent,
        FormlyNgFieldDateComponent,
        FormlyLegalAnnualTurnOverSelectComponent,
        FormlyNgFieldCountryComponent,
        FormlyNgFieldRadioComponent,
        FormlyFileChooserComponent,
        FormlyDocumentTypeSelectComponent,
        FormlyCurrencyInputComponent,
        FormlyTextareaComponent,
        FirstPipe,
        ButtonHistoryBackComponent,
        WalletTransactionListComponent,
        WalletFetcherPipe,
        WalletAvatarComponent,
        AdminDatepickerComponent,
        WalletStateClassPipe,
        FormlyLegalFormSelectComponent,
        FormlyLegalNetIncomeRangeSelectComponent,
        FormlyCseemployeStateSelectComponent,
        FormlyLegalNumberOfEmployeeRangeSelectComponent,
        FormlyCseStateSelectComponent,
        FormlyIconInputComponent,
        TrzCardAvatarComponent,
        FormlyPhoneNumberComponent,
        CseSelectComponent,
        ButtonFileChooserComponent,
        DocumentTypeSelectComponent,
        PreviewDocComponent,
        CseDocumentListComponent,
        KycDocumentUploadComponent,
        CseDocumentStatutPipe,
        CategorySelectComponent,
        IconInputComponent,
        FormlyCategorySelectComponent,
        BooleanPipe,
        AdminDatetimePickerComponent,
        FormlyNgFieldDateTimeComponent,
        AffiliateStateClassPipe,
        CseemployeStateSelectComponent,
        CseStateSelectComponent,
        CseEmployeCardStateUserSelectComponent,
        DateAgoPipe,
        ButtonTrzDocumentComponent,
        ButtonKycreviewComponent,
        CseDocumentSynthesisComponent,
        ModalConfirmationComponent,
        WalletCardComponent,
        TransactionFilterComponent,
        TrzViewToolsComponent,
        CseDocumentTreezorCommentPipe,
        CseTreezorCommentPipe,
        CseUserTreezorCommentPipe,
        FormlyAmountInputComponent,
        FormlyIbanInputComponent
    ],
    imports: [
        UiModule,
        CommonModule,
        NbButtonModule,
        NbIconModule,
        RouterModule,
        NgxTimelineAlbeModule,
        NbTooltipModule,
        NbCardModule,
        NbAccordionModule,
        NgxJsonViewerModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        NbInputModule,
        CdkTableModule,
        MatSortModule,
        MatPaginatorModule,
        CoreservicesModule,
        NbInputModule,
        MatDatepickerModule,
        MatMomentDateModule,
        NbFormFieldModule,
        NbToggleModule,
        NbDatepickerModule,
        NbCheckboxModule,
        NbAutocompleteModule,
        NbRadioModule,
        MatProgressBarModule,
        NgxFileHelpersModule,
        NbSpinnerModule,
        NbContextMenuModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        MatIconModule,
        MatTableModule,
        NbAlertModule,
        NgxIntlTelInputModule,
        NgxCurrencyModule,
        NgxIbanModule,
        NbSelectModule
    ],
    providers: [
        WsClassPipe,
        LoglevelClassPipe,
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD/MM/YYYY'
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                }
            }
        }
    ],
    exports: [
        UiModule,
        WsClassPipe,
        LoglevelClassPipe,
        CseEmployeAvatarComponent,
        TrzWalletAvatarComponent,
        CseUserAvatarComponent,
        ButtonLogasComponent,
        LegalFormSelectComponent,
        LegalAnnualTurnOverSelectComponent,
        AffiliateUserAvatarComponent,
        AffiliateInfoComponent,
        LegalNetIncomeRangeSelectComponent,
        LegalNumberOfEmployeeRangeSelectComponent,
        KycDocumentUploadComponent,
        FormlyFileChooserComponent,
        WalletTransactionListComponent,
        FirstPipe,
        ButtonHistoryBackComponent,
        AdminDatepickerComponent,
        WalletFetcherPipe,
        ButtonTrzUserComponent,
        WalletStateClassPipe,
        WalletAvatarComponent,
        TrzCardAvatarComponent,
        CseSelectComponent,
        CseDocumentListComponent,
        CategorySelectComponent,
        FormlyCategorySelectComponent,
        BooleanPipe,
        AffiliateStateClassPipe,
        CseemployeStateSelectComponent,
        CseStateSelectComponent,
        CseEmployeCardStateUserSelectComponent,
        DateAgoPipe,
        ButtonKycreviewComponent,
        CseDocumentSynthesisComponent,
        PreviewDocComponent,
        WalletCardComponent,
        TransactionFilterComponent,
        TrzViewToolsComponent,
        CseUserTreezorCommentPipe,
        CseTreezorCommentPipe
    ]
})

export class CrmSharedModule {
}
