<div *ngIf="loading$" [nbSpinner]="loading$ | async">
    <ng-container *ngIf="affiliate$ | async | first as affiliate">
        <nb-card>
            <nb-card-header>
                <div class="d-flex align-items-center">
                    <div>
                        <mcv-button-history-back [backUrl]="'/crm/affiliate/affiliates'"></mcv-button-history-back>
                    </div>
                    <div class="ml-2">{{affiliate.legalName}}</div>
                    <div class="ml-auto d-flex align-items-center">
                        <mcv-button-logas
                            *ngIf="affiliate.affiliate_user && affiliate.affiliate_user.length>0"
                            [id]="affiliate.affiliate_user[0].id"
                            [name]="affiliate.legalName"
                            [size]="'tiny'"
                            [type]="'CLIENT_PRO'"></mcv-button-logas>
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card>
            <nb-card-body>
                <nb-tabset (changeTab)="changeTab($event, affiliate.id)" fullWidth>
                    <nb-tab [active]="activeRoute === 'info'"
                            responsive
                            tabId="info" tabTitle="Info. générales">
                        <mcv-affiliate-view-info [affiliate]="affiliate"></mcv-affiliate-view-info>
                    </nb-tab>
                    <nb-tab [active]="activeRoute === 'users'"
                            responsive
                            tabId="users"
                            tabTitle="Utilisateurs">
                        <mcv-user-index [affiliate]="affiliate"></mcv-user-index>
                    </nb-tab>
                    <nb-tab [active]="activeRoute === 'tpes'"
                            responsive
                            tabId="tpes"
                            tabTitle="Tpes">
                        <mcv-tpe-index [affiliate]="affiliate"></mcv-tpe-index>
                    </nb-tab>
                </nb-tabset>
            </nb-card-body>
        </nb-card>
    </ng-container>
</div>
