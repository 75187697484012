import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {Mixin, settings} from 'ts-mixer';
import {PhoneInputComponent} from '@mcv/ui';

settings.initFunction = 'init';

@Component({
    selector: 'mcv-formly-ng-phone',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <br/>
        <input nbInput
               type="tel"
               fullWidth
               [formControl]="formControl"
               [formlyAttributes]="field"
               pattern="\\d*"
               [class]="className"
               [ngClass]="{'ng-valid':formControl.valid,'ng-invalid':!formControl.valid,'ng-touched':formControl.touched,'ng-untouched':!formControl.touched,'ng-dirty':formControl.dirty,'ng-pristine':!formControl.dirty}"/>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
// @ts-ignore
export class FormlyPhoneNumberComponent extends Mixin(FieldType, PhoneInputComponent) {
}



