import {Component, Input, OnInit} from '@angular/core';
import {Affiliate} from '@mcv/core';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';
import {CseEmployeDetailComponent} from '@crm/app/cse-employes/ui/cse-employe-detail/cse-employe-detail.component';

@Component({
    selector: 'mcv-affiliate-info',
    templateUrl: './affiliate-info.component.html',
    styleUrls: ['./affiliate-info.component.scss']
})
export class AffiliateInfoComponent implements OnInit {

    @Input() affiliate: Affiliate;
    @Input() displayName = true;
    @Input() displayAddress = true;

    constructor(private windowService: WindowManagerService) {
    }

    ngOnInit(): void {
    }

    openDetail() {
        this.windowService.open(CseEmployeDetailComponent, {
            windowClass: 'large',
            title: `Détail affilié #${this.affiliate.id}`,
            context: {affiliate_public_id: this.affiliate.public_id}
        }
        );
    }
}
