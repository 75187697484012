import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@crm/app/auth/guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/crm/dashboard',
        pathMatch: 'full'
    },
    {
        path: 'crm',
        canLoad: [AuthGuard],
        loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false,
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
            scrollOffset: [0, 50],
            relativeLinkResolution: 'legacy'
        })
    ],
    exports: [RouterModule]
})

export class ApproutingModule {
}
