import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-user-view',
    templateUrl: './user-view.component.html',
    styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
