import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'mcv-affiliate-index',
    templateUrl: './affiliate-index.component.html',
    styleUrls: ['./affiliate-index.component.scss']
})
export class AffiliateIndexComponent implements OnInit {

    searchFilter: FormGroup = this.fb.group({
        legalName: [],
        withAdmin: [1]
    });

    constructor(private fb: FormBuilder) {
    }

    ngOnInit(): void {
    }

    setDate(field: string, $event: Date) {
        this.searchFilter
            .get(field)
            .patchValue(`${$event.getFullYear()}/${$event.getMonth() + 1}/${$event.getDate()}`);
    }

}
