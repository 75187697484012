import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'loglevelClass'
})
export class LoglevelClassPipe implements PipeTransform {

    transform(level: string, ...args: unknown[]): string {
        switch (level) {
        case 'success':
            return 'success';
        case 'debug':
        case 'info':
            return 'info';
        case 'error':
            return 'danger';
        default:
            return 'primary';
        }
    }

}
