import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {CseDocumentService} from '@mcv/coreservices';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {McvnotifierService} from '@mcv/ui';
import {map, tap} from 'rxjs/operators';

@Component({
    selector: 'mcv-document-type-select',
    templateUrl: './document-type-select.component.html',
    styleUrls: ['./document-type-select.component.scss']
})
export class DocumentTypeSelectComponent implements OnInit, ControlValueAccessor {
    @Input() value: string = null;
    @Input() disabled: boolean;
    items: { value: number, label: string }[];
    loading = false;

    constructor(private cseDocumentService: CseDocumentService,
                @Self() @Optional() public control: NgControl,
                private notifierService: McvnotifierService) {
        this.control && (this.control.valueAccessor = this);
    }

    onChange: any = (val: number) => {
        console.log(val);
    };

    onTouch: any = (val: number) => {
    };

    ngOnInit(): void {
        if (!this.items) {
            this.loadItems();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }

    loadItems() {
        this.loading = true;
        this.cseDocumentService
            .listTypes()
            .pipe(
                map(r => r.data),
                tap(_ => this.loading = false)
            )
            .toPromise()
            .then(r => {
                this.items = [];
                Object.values(r).forEach((c: Record<number, string>) => {
                    Object.keys(c).forEach(k => {
                        this.items.push({label: c[k], value: +k});
                    });
                });
            })
            .catch(err => {
                this.notifierService.error(err);
                this.loading = false;
            });
    }
}
