import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {BASEURL} from '@mcv/config';
import {McvnotifierService} from '@mcv/ui';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {ConfigurationsService} from '../../services/configurations.service';

@Component({
    selector: 'mcv-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    saving = false;
    subscription: Subscription = new Subscription();
    error: string;
    loading: any;

    constructor(@Inject(BASEURL) public baseUrl: string,
                private fb: FormBuilder,
                private router: Router,
                private configurationService: ConfigurationsService,
                private notifierService: McvnotifierService,
                private authService: AuthService) {
    }

    async ngOnInit(): Promise<void> {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    login(): void {
        this.loading = true;
        this.authService
            .login()
            .toPromise()
            .then(url => {
                window.location.href = url;
            })
            .catch(err => {
                this.error = err;
                this.loading = false;
            });
    }
}
