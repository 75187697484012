import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Affiliate} from '@mcv/core';

@Component({
    selector: 'mcv-tpe-index',
    templateUrl: './tpe-index.component.html',
    styleUrls: ['./tpe-index.component.scss']
})
export class TpeIndexComponent implements OnInit {

    @Input() affiliate: Affiliate;

    searchFilter: FormGroup = this.fb.group({
        filter: [],
        withAffiliateId: []
    });

    constructor(private fb: FormBuilder) {

    }

    ngOnInit(): void {
        if (this.affiliate) {
            this.searchFilter.patchValue({
                withAffiliateId: [this.affiliate.id]
            });
        }
    }

    setDate(field: string, $event: Date) {
        this.searchFilter
            .get(field)
            .patchValue(`${$event.getFullYear()}/${$event.getMonth() + 1}/${$event.getDate()}`);
    }
}
