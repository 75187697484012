import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TrzTransaction} from '@crm/interfaces/TrzTransaction';
import {BASEURL} from '@mcv/config';
import {BaseRepository} from '@mcv/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminTransactionService extends BaseRepository<TrzTransaction> {
    model = 'admin/transaction';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }

    sync(walletId: number): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/${this.model}/sync/${walletId}`);
    }
}
