import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASEURL } from '@mcv/config';
import {
    BaseRepository,
    CardStatus,
    CseEmployeCard,
    CseEmployeCardStateUserEnum, CseEmployeCardStatusCode,
    SingleResult,
    TrzCardImage
} from '@mcv/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CseEmployeCardService extends BaseRepository<CseEmployeCard> {
    model = 'CseEmployeCard';

    constructor(
        protected httpClient: HttpClient,
        @Inject(BASEURL) baseUrl: string,
        private router: Router
    ) {
        super(httpClient, baseUrl);
    }

    lockCard(cseEmployeCardId: string): Observable<SingleResult<any>> {
        return this.setLockCard(cseEmployeCardId, CseEmployeCardStateUserEnum.LOCK);
    }

    unlockCard(cseEmployeCardId: string): Observable<SingleResult<any>> {
        return this.setLockCard(cseEmployeCardId, CseEmployeCardStateUserEnum.UNLOCK);
    }

    unblockPin(password: string, cseEmployeCardId: string): Observable<SingleResult<any>> {
        return this.httpClient.put<SingleResult<any>>(`${this.baseUrl}/${this.model}/unblockPin`, {
            password,
            cseEmployeCardId
        });
    }

    pinView(password: string, cseEmployeCardId: string): Observable<SingleResult<string>> {
        return this.httpClient.put<SingleResult<string>>(`${this.baseUrl}/${this.model}/pinView`, {
            password,
            cseEmployeCardId
        });
    }

    getCardImage(password: string, cseEmployeCardId: string): Observable<SingleResult<TrzCardImage>> {
        return this.httpClient.put<SingleResult<TrzCardImage>>(`${this.baseUrl}/${this.model}/getCardImage`, {
            password,
            cseEmployeCardId
        });
    }

    brokenCard(cseEmployeCardId: string): Observable<SingleResult<any>> {
        return this.setLockCard(cseEmployeCardId, CseEmployeCardStateUserEnum.BROKEN);
    }

    stolenCard(cseEmployeCardId: string): Observable<SingleResult<any>> {
        return this.setLockCard(cseEmployeCardId, CseEmployeCardStateUserEnum.STOLEN);
    }

    lostCard(cseEmployeCardId: string): Observable<SingleResult<any>> {
        return this.setLockCard(cseEmployeCardId, CseEmployeCardStateUserEnum.LOST);
    }

    setLockCard(cseEmployeCardId: string, statut: string): Observable<SingleResult<any>> {
        return this.httpClient.put<SingleResult<any>>(`${this.baseUrl}/${this.model}/setLockCard`, {
            cseEmployeCardId,
            statut
        });
    }

    getCardStatus(cseEmployeCardPublicId?: string): Observable<SingleResult<CardStatus>> {
        const param = cseEmployeCardPublicId ? '?employeCardPublicId=' + cseEmployeCardPublicId : '';
        return this.httpClient.get<SingleResult<CardStatus>>(`${this.baseUrl}/${this.model}/getCardStatus${param}`);
    }

    confirmCardCommand(employe_id: string, confirm: boolean): Observable<SingleResult<any>> {
        return this.httpClient.put<SingleResult<any>>(`${this.baseUrl}/cse-employe/cse-employe-purchase/confirmCommandCardFromCse`, {
            public_id: employe_id,
            valid_command: confirm
        });
    }

    async isEmployeAbleToViewRenewCardScreen() {
        const response = await this.getCardStatus()
            .pipe(map(r => r.data))
            .toPromise();

        switch (response.code) {
            case CseEmployeCardStatusCode.ORDER_IN_PROGRESS:
                await this.router.navigateByUrl('/client/carte/renouvellement/en-cours');
                break;
            case CseEmployeCardStatusCode.ACTIVE:
                break;
            default:
                await this.router.navigateByUrl('/carte');
        }
    }
}
