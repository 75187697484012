import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'affiliateStateClass'
})
export class AffiliateStateClassPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        switch (value) {
        case 'sync' :
            return 'success';
        case 'refused' :
        case 'closed' :
        case 'sync_toclose' :
            return 'danger';
        default:
            return 'info';
        }
    }

}
