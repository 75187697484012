import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Tpe} from '@mcv/core';
import {AffiliateTpeService} from '@mcv/coreservices';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {McvnotifierService} from '@mcv/ui';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-affiliate-tpe-detail',
    templateUrl: './affiliate-tpe-detail.component.html',
    styleUrls: ['./affiliate-tpe-detail.component.scss']
})
export class AffiliateTpeDetailComponent implements OnInit {

    @Input() affiliate_tpe_public_id: string;
    affiliateTpe$: Observable<Tpe> = new Observable<Tpe>();

    constructor(private affiliateTpeService: AffiliateTpeService,
                private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private notifierService: McvnotifierService) {
    }

    ngOnInit(): void {
        this.readRouteParams();
        if (this.affiliate_tpe_public_id) {
            this.load();
        }
    }

    private readRouteParams() {
        if (this.activatedRoute.snapshot.queryParamMap.has('affiliate_tpe_public_id')) {
            this.affiliate_tpe_public_id = this.activatedRoute.snapshot.queryParamMap.get('affiliate_tpe_public_id');
        }
    }

    private load() {
        this.affiliateTpe$ = this.affiliateTpeService
            .view(this.affiliate_tpe_public_id)
            .pipe(map(r => r.data));
    }

}
