import {Component} from '@angular/core';
import {NbIconLibraries} from '@nebular/theme';

@Component({
    selector: 'mcv-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(private iconLibraries: NbIconLibraries) {
        this.iconLibraries.registerFontPack('far', {packClass: 'far', iconClassPrefix: 'fa'});
        this.iconLibraries.registerFontPack('fas', {packClass: 'fas', iconClassPrefix: 'fa'});
    }
}
