import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'mcv-button-history-back',
    templateUrl: './button-history-back.component.html',
    styleUrls: ['./button-history-back.component.scss']
})
export class ButtonHistoryBackComponent implements OnInit {
    @Input() backUrl: string;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    goBack() {
        if (this.backUrl) {
            return this.router.navigate([this.backUrl]);
        }
        window.history.back();
    }
}
