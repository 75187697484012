import {Component, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {Mixin, settings} from 'ts-mixer';
import {CategorySelectComponent} from '../category-select/category-select.component';

settings.initFunction = 'init';

@Component({
    selector: 'mcv-formly-category-select',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <ng-select [items]="items"
                   class="size-medium status-basic"
                   [ngClass]="!formControl?.valid?'ng-invalid':''"
                   groupBy="root_cat"
                   [loading]="loading"
                   appendTo="body"
                   bindValue="value"
                   bindLabel="label"
                   [formControl]="formControl"
                   [formlyAttributes]="field"
        >
            <ng-template ng-optgroup-tmp let-item="item">
                <b>{{item.root_cat | titlecase}}</b>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                {{item.label}}
            </ng-template>
        </ng-select>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
// @ts-ignore
export class FormlyCategorySelectComponent extends Mixin(FieldType, CategorySelectComponent) implements OnInit {

    ngOnInit(): void {
        if (this.to['level']) {
            this.level = this.to['level'];
        }
        super.ngOnInit();
    }
}
