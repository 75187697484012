<div class="d-flex align-items-center">
    <span *ngIf="displayName" class="h6 mr-2 name--span__weight">
        {{affiliate?.legalName | uppercase}}
    </span>
</div>
<div *ngIf="displayAddress" class="mt-1">
    <div *ngIf="affiliate.address1">
        <span>{{affiliate.address1}}</span>
    </div>
    <div *ngIf="affiliate.address2">
        <span>{{affiliate.address2}}</span>
    </div>
    <div *ngIf="affiliate.postcode">
        <span>{{affiliate.postcode}}</span>
    </div>
    <div *ngIf="affiliate.city">
        <span>{{affiliate.city}}</span>
    </div>
    <div *ngIf="affiliate.country">
        <span>{{affiliate.country}}</span>
    </div>
</div>
