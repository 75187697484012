import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'mcv-formly-iban-input',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <input ngxIban
               nbInput
               fullWidth
               [formControl]="formControl"
               [formlyAttributes]="field"/>
        <val-errors controlName="{{key}}">
            <ng-template valError="iban">IBAN incorrect</ng-template>
        </val-errors>
    `
})
export class FormlyIbanInputComponent extends FieldType {
}
