import {Component} from '@angular/core';
import {IconInputComponent} from '@crm/app/shared/ui/icon-input/icon-input.component';
import {FieldType} from '@ngx-formly/core';
import {Mixin, settings} from 'ts-mixer';

settings.initFunction = 'init';

@Component({
    selector: 'mcv-formly-icon-input',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <nb-form-field>
            <nb-icon nbPrefix icon="{{formControl.value}}" pack="far"></nb-icon>
            <input placeholder="{{to.placeholder}}"
                   fullWidth
                   type="text"
                   nbInput
                   [formControl]="formControl"
                   [formlyAttributes]="field">
            <a nbSuffix href="https://fontawesome.com/" target="_blank">
                <i class="fas fa-external-link"></i>
            </a>
        </nb-form-field>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
// @ts-ignore
export class FormlyIconInputComponent extends Mixin(FieldType, IconInputComponent) {

}
