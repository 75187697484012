import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {Mixin, settings} from 'ts-mixer';
import {DocumentTypeSelectComponent} from '../document-type-select/document-type-select.component';

settings.initFunction = 'init';

@Component({
    selector: 'mcv-formly-document-type-select',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <ng-select [items]="items"
                   class="size-medium status-basic"
                   [ngClass]="!formControl?.valid?'ng-invalid':''"
                   [loading]="loading"
                   appendTo="body"
                   bindValue="value"
                   bindLabel="label"
                   (blur)="onTouch()"
                   [formControl]="formControl"
        ></ng-select>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
// @ts-ignore
export class FormlyDocumentTypeSelectComponent extends Mixin(FieldType, DocumentTypeSelectComponent) {
}
