import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'mcv-formly-ng-field-input',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <input fullWidth
               type="{{to.type !== null ? to.type : 'text'}}"
               nbInput
               [formControl]="formControl"
               [formlyAttributes]="field">
        <val-errors controlName="{{key}}"></val-errors>
    `
})
export class FormlyNgFieldInputComponent extends FieldType {
}
