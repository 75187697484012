import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {environment} from '@crm/environments/environment';
import {BASEURL} from '@mcv/config';
import {AuthResult, SingleResult} from '@mcv/core';
import * as moment from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from 'apps/crm/src/interfaces/User';

const TOKEN = 'crm_id_token' + environment.client_env;
const EXPIREAT = 'crm_expires_at' + environment.client_env;

/**
 * @todo : à factoriser avec le CseEmploye
 */
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    model = 'admin/user';
    user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    constructor(private httpClient: HttpClient,
                @Inject(BASEURL) protected baseUrl: string) {
    }

    private _user: User;

    get user(): User {
        return this._user;
    }

    set user(user: User) {
        this._user = user;
        this.user$.next(this._user as User);
    }

    // tslint:disable-next-line:variable-name
    login(): Observable<string> {
        return this.httpClient
            .get<SingleResult<string>>(`${this.baseUrl}/${this.model}/login`)
            .pipe(
                map(r => r.data)
            );

    }

    setSession(authResult: AuthResult): void {
        const expiresAt = moment()
            .add(authResult.expiresIn, 'second');
        localStorage.setItem(TOKEN, authResult.token);
        localStorage.setItem(EXPIREAT, JSON.stringify(expiresAt.valueOf()));
    }

    logout(): void {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(EXPIREAT);
    }

    isLoggedIn(): boolean {
        return localStorage.getItem(EXPIREAT) && moment()
            .isBefore(this.getExpiration());
    }

    isLoggedOut(): boolean {
        return !this.isLoggedIn();
    }

    getExpiration(): any {
        const expiration = localStorage.getItem(EXPIREAT);
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    getToken(): string {
        return localStorage.getItem(TOKEN);
    }

    getLogas(id: number, type: string): Observable<SingleResult<string>> {
        return this.httpClient.get<SingleResult<string>>(`${this.baseUrl}/${this.model}/cryptlogas/${id}/${type}`);
    }
}
