import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'first'
})
export class FirstPipe implements PipeTransform {

    transform<T>(value: T[], ...args: unknown[]): T {
        return value && value.length > 0 ? value[0] : null;
    }
}
