import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    NbAccordionModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbCardModule,
    NbDatepickerModule,
    NbInputModule,
    NbSpinnerModule,
    NbTabsetModule,
    NbTooltipModule
} from '@nebular/theme';
import {ReactiveFormsModule} from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {CdkTableModule} from '@angular/cdk/table';
import {MatSortModule} from '@angular/material/sort';
import {UiModule} from '@mcv/ui';
import {NbDateFnsDateModule} from '@nebular/date-fns';
import {AffiliateRoutingModule} from './affiliate-routing.module';
import {fr} from 'date-fns/locale';
import {AffiliateListComponent} from './ui/affiliate-list/affiliate-list.component';
import {CrmSharedModule} from '../shared/crm-shared.module';
import {TpeListComponent} from './ui/tpe-list/tpe-list.component';
import {TpeViewComponent} from './pages/tpe/tpe-view.component';
import {TpeIndexComponent} from './pages/tpe/tpe-index.component';
import {AffiliateIndexComponent} from '@crm/app/affiliate/pages/affiliates/affiliate-index.component';
import {AffiliateViewComponent} from '@crm/app/affiliate/pages/affiliates/affiliate-view.component';
import {UserViewComponent} from './pages/users/user-view.component';
import {UserIndexComponent} from './pages/users/user-index.component';
import {UserListComponent} from './ui/user-list/user-list.component';
import {AffiliateViewInfoComponent} from './ui/affiliate-view-info/affiliate-view-info.component';
import {FormlyModule} from '@ngx-formly/core';
import {AffiliateUserViewComponent} from './ui/affiliate-user-view/affiliate-user-view.component';
import {AffiliateUserDetailComponent} from './ui/affiliate-user-detail/affiliate-user-detail.component';
import {AffiliateTpeViewComponent} from './ui/affiliate-tpe-view/affiliate-tpe-view.component';
import {AffiliateTpeDetailComponent} from './ui/affiliate-tpe-detail/affiliate-tpe-detail.component';

@NgModule({
    declarations: [
        AffiliateIndexComponent,
        AffiliateViewComponent,
        TpeViewComponent,
        TpeListComponent,
        TpeIndexComponent,
        AffiliateListComponent,
        UserViewComponent,
        UserIndexComponent,
        UserListComponent,
        AffiliateViewInfoComponent,
        AffiliateUserViewComponent,
        AffiliateUserDetailComponent,
        AffiliateTpeViewComponent,
        AffiliateTpeDetailComponent
    ],
    imports: [
        CommonModule,
        AffiliateRoutingModule,
        NbCardModule,
        NbAccordionModule,
        ReactiveFormsModule,
        NbInputModule,
        MatMenuModule,
        MatPaginatorModule,
        CdkTableModule,
        MatSortModule,
        NbButtonModule,
        NbTooltipModule,
        UiModule,
        NbTabsetModule,
        NbSpinnerModule,
        NbDatepickerModule,
        NbDateFnsDateModule.forChild({
            format: 'yyyy-MM-dd',
            formatOptions: {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
                locale: fr
            },
            parseOptions: {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
                locale: fr
            }
        }),
        NbAutocompleteModule,
        CrmSharedModule,
        FormlyModule.forChild()
    ]
})
export class AffiliateModule {
}
