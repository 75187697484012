import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {NgxTimelineAlbeComponent} from './ngx-timeline-albe.component';
import {SafeHtmlPipe} from './safe-html.pipe';
import {ReplaceTagDirective} from './tag-replace.directive';


@NgModule({
    declarations: [
        NgxTimelineAlbeComponent,
        ReplaceTagDirective,
        SafeHtmlPipe
    ],
    imports: [
        CommonModule,
    ],
    exports: [NgxTimelineAlbeComponent]
})
export class NgxTimelineAlbeModule {
}
