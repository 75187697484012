import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {CseEmployeStates} from '@mcv/core';

@Component({
    selector: 'mcv-cseemploye-state-select',
    templateUrl: './cseemploye-state-select.component.html',
    styleUrls: ['./cseemploye-state-select.component.scss']
})
export class CseemployeStateSelectComponent implements OnInit, ControlValueAccessor {
    @Input() value: string = null;
    @Input() disabled: boolean;
    items: { value: string, label: string }[] = CseEmployeStates;
    loading = false;

    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    onChange: any = (val: number) => {
    };

    onTouch: any = (val: number) => {
    };

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }
}
