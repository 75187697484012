import {Injectable} from '@angular/core';
import {AuthService} from '@crm/app/auth/services/auth.service';
import {environment} from '@crm/environments/environment';
import {Socket, SocketIoConfig} from 'ngx-socket-io';

const config: SocketIoConfig = {url: environment.socketIo + '/crm'};

@Injectable({
    providedIn: 'root'
})
export class SocketService extends Socket {

    constructor(private authService: AuthService) {
        super(config);
        this.ioSocket.query = {
            token: (() => this.authService.getToken() ? this.authService.getToken() : '')()
        };
    }
}
