<ng-select (blur)="onTouch()"
           (ngModelChange)="onChange($event)"
           [groupBy]="level === 1 ? 'root_cat':null"
           [items]="items"
           [loading]="loading"
           [ngClass]="control.status==='INVALID'?'ng-invalid':''"
           [ngModel]="value"
           appendTo="body"
           bindLabel="label"
           bindValue="value"
           class="size-medium status-basic"
></ng-select>
