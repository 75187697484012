import {CdkDrag, DragDrop} from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {WindowManagerConfigOptions} from '@crm/app/window-manager/interfaces/WindowManagerConfigOptions';
import {WINDOW_CONFIG_TOKEN} from '@crm/app/window-manager/utils/config-token';

enum WindowState {
    regular = 'regular',
    minimize = 'minimize',
    maximize = 'maximize'
}

@Component({
    selector: 'mcv-window-manager-modal',
    templateUrl: './window-manager-modal.component.html',
    styleUrls: ['./window-manager-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WindowManagerModalComponent<T> implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(CdkDrag) containerElement: CdkDrag;
    @ViewChild('bodymodal', {static: true}) bodymodal: ElementRef;
    @ViewChild('windowmanagerWrapper', {static: true}) wrapper: ElementRef;
    @ViewChild('anchor', {static: true, read: ViewContainerRef}) anchor: ViewContainerRef;
    @Input() component: Type<T>;
    position: number;
    buttonClass: string;
    headerClass: string;
    wrapperClass: string;
    windowClass: string;
    bodyClass: string;
    onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
    onMinimize: EventEmitter<boolean> = new EventEmitter<boolean>();
    onRegular: EventEmitter<boolean> = new EventEmitter<boolean>();
    onFocus: EventEmitter<any> = new EventEmitter<any>();
    state: WindowState = WindowState.regular;
    WindowState = WindowState;
    title;
    oldPosition: { left: number, top: number };
    oldStyle: any;
    active = true;
    context: any;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private dragDropService: DragDrop,
                @Inject(WINDOW_CONFIG_TOKEN) config: WindowManagerConfigOptions) {
        if (config) {
            this.applyDefaultConfig(config);
        }
    }

    @HostListener('click')
    clickInside() {
        this.onFocus.emit(this);
    }

    applyDefaultConfig(config: WindowManagerConfigOptions) {
        for (const option in config) {
            this[option] = config[option];
        }
    }

    close() {
        this.onClose.emit(true);
    }

    ngOnInit(): void {
        if (this.component && this.anchor) {
            this.createComponent();
        }
        this.wrapper.nativeElement.style.top = '50%';
        this.wrapper.nativeElement.style.left = '50%';
        this.wrapper.nativeElement.style.transform = 'translate(-50%, -50%)';
    }

    ngAfterViewInit(): void {
        window.setTimeout(() => {
            const position2 = this.wrapper.nativeElement.getBoundingClientRect();
            this.wrapper.nativeElement.style.top = Math.round(position2.top) + 'px';
            this.wrapper.nativeElement.style.left = Math.round(position2.left) + 'px';
            this.wrapper.nativeElement.style.height = position2.height + 'px';
            this.wrapper.nativeElement.style.width = position2.width + 'px';
            this.wrapper.nativeElement.style.transform = '';
            this.bodymodal.nativeElement.focus();
        }, 500);
    }

    ngOnDestroy(): void {
    }

    minimize() {
        // @ts-ignore
        this.containerElement._dragRef._initialTransform = null;
        this.oldStyle = {...this.wrapper.nativeElement.style};
        this.oldPosition = this.wrapper.nativeElement.getBoundingClientRect();
        this.state = WindowState.minimize;
        this.containerElement.reset();
        this.onMinimize.emit(true);
        this.wrapper.nativeElement.style = '';
    }

    regular() {
        this.state = WindowState.regular;
        this.onRegular.emit(true);
        if (this.oldStyle) {
            this.wrapper.nativeElement.style.height = this.oldStyle.height;
            this.wrapper.nativeElement.style.width = this.oldStyle.width;
            this.wrapper.nativeElement.style.left = this.oldStyle.left + 'px';
            this.wrapper.nativeElement.style.top = this.oldStyle.top + 'px';
        }
        if (this.oldPosition) {
            this.wrapper.nativeElement.style.left = this.oldPosition.left + 'px';
            this.wrapper.nativeElement.style.top = this.oldPosition.top + 'px';
        }
        this.wrapper.nativeElement.style.transform = '';
    }

    createComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
        const componentRef: ComponentRef<any> = this.anchor.createComponent(componentFactory);
        this.projectComponentInputs(componentRef, this.context);
    }

    projectComponentInputs(component: ComponentRef<any>, options: any): ComponentRef<any> {
        if (options) {
            const props = Object.getOwnPropertyNames(options);
            for (const prop of props) {
                component.instance[prop] = component.instance[prop] ? (component.instance[prop] + options[prop]) : options[prop];
            }
        }
        if (component.instance['closeModal']) {
            component.instance['closeModal'] = this.onClose;
        }
        return component;
    }
}
