import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'wsClass'
})
export class WsClassPipe implements PipeTransform {

    transform(value: any, ...args: string[]): unknown {
        const field = args[0];
        if (value.hasOwnProperty(field) && value.hasOwnProperty('_ws_class') && value['_ws_class'].hasOwnProperty(field)) {
            return value['_ws_class'][field];
        }
        return null;
    }

}
