import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'mcv-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    error: string;

    constructor(private activatedRoute: ActivatedRoute) {
        this.error = this.activatedRoute.snapshot.queryParamMap.get('error');
    }

    ngOnInit(): void {
    }

}
