import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {ReadFile} from 'ngx-file-helpers';
import {Subscription} from 'rxjs';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {WindowManagerService} from '@crm/app/window-manager/services/window-manager.service';
import {PreviewDocComponent} from '@crm/app/shared/ui/preview-doc/preview-doc.component';

@Component({
    selector: 'mcv-button-file-chooser',
    templateUrl: './button-file-chooser.component.html',
    styleUrls: ['./button-file-chooser.component.scss']
})
export class ButtonFileChooserComponent implements OnInit, ControlValueAccessor {
    @Input() displayPreview = true;
    uploading = false;
    disabled;
    uploadUrl;
    rawData: any;
    file: ReadFile;
    extensions: '.csv, .zip';
    subscription: Subscription;

    constructor(private windowsService: WindowManagerService,
                @Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    onChange: any = (val: number) => {
    };

    onTouch: any = (val: number) => {
    };

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
    }

    onFilePicked($event: ReadFile) {
        this.onChange($event.underlyingFile);
        this.rawData = $event.content;
        this.file = $event;
    }

    openPreview() {
        this.windowsService.open(PreviewDocComponent, {
            title: `Prévisualisation ${this.file.name}`,
            windowClass: 'large',
            context: {
                srcUrl: this.rawData
            }
        });
    }
}
