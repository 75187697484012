import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {Mixin, settings} from 'ts-mixer';
import {CseStateSelectComponent} from '../cse-state-select/cse-state-select.component';

settings.initFunction = 'init';

@Component({
    selector: 'mcv-formly-cse-state-select',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <ng-select [items]="items"
                   class="size-medium status-basic"
                   [ngClass]="!formControl?.valid?'ng-invalid':''"
                   appendTo="body"
                   bindValue="value"
                   bindLabel="label"
                   [formControl]="formControl"
                   [formlyAttributes]="field"
        ></ng-select>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
// @ts-ignore
export class FormlyCseStateSelectComponent extends Mixin(FieldType, CseStateSelectComponent) {
}
