import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'mcv-formly-currency-input',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <nb-form-field>
            <nb-icon nbSuffix icon="euro-sign" pack="far"></nb-icon>
            <input fullWidth
                   type="number"
                   step="0.01"
                   nbInput
                   [formControl]="formControl"
                   [formlyAttributes]="field"
                   (mousewheel)="$event.target.blur()">
        </nb-form-field>
        <val-errors controlName="{{key}}"></val-errors>
    `,
    styles: [`
        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }
    `]
})
export class FormlyCurrencyInputComponent extends FieldType {
}
