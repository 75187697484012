import {Component, Inject, Input, LOCALE_ID, OnChanges, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {I18n} from './i18n';

import {TimelineItem} from './timeline-item';

const DEFAULT_OPTIONS: any = {
    effect: 'zoomInUp',
    showGroup: true,
    showMenu: true,
    formatDate: 'dd MMMM',
    language: 'pt-BR',
    sortDesc: true,
    theme: 'basic'
};

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ngx-timeline',
    templateUrl: './ngx-timeline-albe.component.html',
    styleUrls: ['./ngx-timeline-albe.component.scss'],
    encapsulation: ViewEncapsulation.None,
    //changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxTimelineAlbeComponent implements OnInit, OnChanges {
    @Input() itemTemplate: TemplateRef<{ item: any }>;
    emptyContent = '';
    lstGroup!: Array<any>;

    //List of itens
    @Input()
    items: Array<TimelineItem> | String = [];
    //Effect of presentation
    //'fadeInUp', 'bounceIn', etc
    @Input()
    effect: string = DEFAULT_OPTIONS.effect;
    //Sets the visibility of the annual grouper
    @Input()
    showGroup: boolean = DEFAULT_OPTIONS.showGroup;
    //Sets the anchor menu visibility for annual groupings (depends on 'showGroup')
    @Input()
    showMenu: boolean = DEFAULT_OPTIONS.showMenu;
    //Sets the date display format
    //'dd/MM/yyyy', 'dd de MMMM de yyyy HH:mm:ss', etc
    @Input()
    formatDate: string = DEFAULT_OPTIONS.formatDate;
    //Defines ordering of items
    //true: Descendente
    //false: Ascendente
    @Input()
    sortDesc: boolean = DEFAULT_OPTIONS.sortDesc;
    //Defines the style
    @Input()
    theme: string = DEFAULT_OPTIONS.theme;

    constructor(@Inject(LOCALE_ID) protected localeID: string) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        // Se for passado 'string', convert para 'object'.
        if (typeof this.items === 'string') {
            this.items = JSON.parse(this.items);
        }

        // Ordena pela data.
        this.items = (<Array<TimelineItem>>this.items)
            .sort((a: TimelineItem, b: TimelineItem) => {
                return (this.sortDesc) ?
                    ((+new Date(b.datetime)) - (+new Date(a.datetime))) :
                    ((+new Date(a.datetime)) - (+new Date(b.datetime)));
            });

        this.lstGroup = this.groupBy(this.items);

        //Specifies the display language of texts (i18n)
        this.emptyContent = I18n.find(element => element.lang === this.localeID || element.lang === DEFAULT_OPTIONS.language)?.messageForEmptyContent ?? '';
    }

    getAnchorID(d: any): string {
        return 'Y' + new Date(d).getUTCFullYear();
    }

    groupBy(colecao: Array<TimelineItem>) {
        const agrupado: { Key: number; Elements: TimelineItem[]; }[] = [];

        colecao.forEach(i => {

            const d1 = new Date(i.datetime).getUTCFullYear();
            let foiAgrupado = false;

            agrupado.forEach(j => {

                if (d1 === j.Key) {
                    j.Elements.push(i);
                    foiAgrupado = true;
                }
            });

            if (!foiAgrupado) {
                agrupado.push({
                    Key: d1,
                    Elements: [i]
                });
            }

        });

        return agrupado;
    }
}
