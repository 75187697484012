import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'mcv-formly-ng-field-input',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <input currencyMask
               [formControl]="formControl"
               [formlyAttributes]="field"
               [options]="to.params"
               nbInput
               fullWidth/>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
export class FormlyAmountInputComponent extends FieldType {
}
