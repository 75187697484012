import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'mcv-formly-textarea',
    template: `
        <label class="mb-2 mt-2" [class.required]="to.required">{{to.label}}</label>
        <textarea fullWidth
                  nbInput
                  rows="5"
                  [formControl]="formControl"
                  [formlyAttributes]="field"></textarea>
        <val-errors controlName="{{key}}"></val-errors>
    `
})
export class FormlyTextareaComponent extends FieldType {
}
