import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {ErrorHandler, Injector, LOCALE_ID, NgModule, Provider} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import {AffiliateModule} from '@crm/app/affiliate/affiliate.module';
import {FormlyAmountInputComponent} from '@crm/app/shared/ui/formly/formly-amount-input.component';
import {FormlyCategorySelectComponent} from '@crm/app/shared/ui/formly/formly-category-select.component';
import {FormlyCseStateSelectComponent} from '@crm/app/shared/ui/formly/formly-cse-state-select';
import {FormlyCseemployeStateSelectComponent} from '@crm/app/shared/ui/formly/formly-cseemploye-state-select';
import {FormlyCurrencyInputComponent} from '@crm/app/shared/ui/formly/formly-currency-input';
import {FormlyDocumentTypeSelectComponent} from '@crm/app/shared/ui/formly/formly-document-type-select.component';
import {FormlyFileChooserComponent} from '@crm/app/shared/ui/formly/formly-file-chooser.component';
import {FormlyIbanInputComponent} from '@crm/app/shared/ui/formly/formly-iban-input.component';
import {FormlyIconInputComponent} from '@crm/app/shared/ui/formly/formly-icon-input.component';
import {
    FormlyLegalAnnualTurnOverSelectComponent
} from '@crm/app/shared/ui/formly/formly-legal-annual-turn-over-select.component';
import {FormlyLegalFormSelectComponent} from '@crm/app/shared/ui/formly/formly-legal-form-select.component';
import {
    FormlyLegalNetIncomeRangeSelectComponent
} from '@crm/app/shared/ui/formly/formly-legal-net-income-range-select.component';
import {
    FormlyLegalNumberOfEmployeeRangeSelectComponent
} from '@crm/app/shared/ui/formly/formly-legal-number-of-employee-range-select.component';
import {FormlyNgFieldCheckboxComponent} from '@crm/app/shared/ui/formly/formly-ng-field-checkbox.component';
import {FormlyNgFieldCountryComponent} from '@crm/app/shared/ui/formly/formly-ng-field-country.component';
import {FormlyNgFieldDateComponent} from '@crm/app/shared/ui/formly/formly-ng-field-date.component';
import {FormlyNgFieldDateTimeComponent} from '@crm/app/shared/ui/formly/formly-ng-field-datetime-component';
import {FormlyNgFieldInputComponent} from '@crm/app/shared/ui/formly/formly-ng-field-input.component';
import {FormlyNgFieldRadioComponent} from '@crm/app/shared/ui/formly/formly-ng-field-radio.component';
import {FormlyNgFieldSelectComponent} from '@crm/app/shared/ui/formly/formly-ng-field-select.component';
import {FormlyNgFieldToggleComponent} from '@crm/app/shared/ui/formly/formly-ng-field-toggle.component';
import {FormlyNgWrapperFieldsetComponent} from '@crm/app/shared/ui/formly/formly-ng-wrapper-fieldset.component';
import {FormlyPhoneNumberComponent} from '@crm/app/shared/ui/formly/formly-phone-number.component';
import {FormlyTextareaComponent} from '@crm/app/shared/ui/formly/formly-textarea.component';
import {StoreModule} from '@crm/app/store/store.module';
import {WindowManagerModule} from '@crm/app/window-manager/window-manager.module';
import {environment} from '@crm/environments/environment';
import {BASEURL} from '@mcv/config';
import {ServiceLocator} from '@mcv/coreservices';
import {UiModule} from '@mcv/ui';
import {NbDateFnsDateModule} from '@nebular/date-fns';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {
    NbButtonModule,
    NbDatepickerModule,
    NbDialogModule,
    NbIconModule,
    NbLayoutModule,
    NbMenuModule,
    NbSidebarModule,
    NbThemeModule,
    NbToastrModule,
    NbWindowModule
} from '@nebular/theme';
import {FormlyModule} from '@ngx-formly/core';
import {fr} from 'date-fns/locale';
import {NgxIbanModule} from 'ngx-iban';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {AppComponent} from './app.component';
import {ApproutingModule} from './approuting.module';
import {AuthModule} from './auth/auth.module';
import {ContributionsModule} from '@crm/app/contributions/contributions.module';
import {httpInterceptorProviders} from '@mcv/core';

export function errorHandlerFactory() {
    if (environment.client_env !== 'local') {
        return new BugsnagErrorHandler();
    }
    return new ErrorHandler();
}

registerLocaleData(localeFr);

const providers: Provider[] = [
    {provide: ErrorHandler, useFactory: errorHandlerFactory},
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: BASEURL, useValue: environment.baseUrl}
];
if (environment.client_env === 'dev' || environment.client_env === 'local') {
    providers.push(httpInterceptorProviders);
}


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        ApproutingModule,
        WindowManagerModule.forRoot({
            bodyClass: 'mcv-card-body',
            headerClass: 'mcv-card-header',
            wrapperClass: 'mcv-card',
            buttonClass: 'mcv-window-button'
        }),
        AffiliateModule,
        NgxJsonViewerModule,
        StoreModule.forRoot(),
        BrowserAnimationsModule,
        AuthModule,
        BrowserModule,
        UiModule,
        NgxIbanModule,
        NbThemeModule.forRoot({name: 'default'}),
        NbLayoutModule,
        NbDialogModule.forRoot({
            closeOnEsc: true
        }),
        NbToastrModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbDateFnsDateModule.forRoot({
            format: 'dd/MM/yyyy',
            formatOptions: {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
                locale: fr
            },
            parseOptions: {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
                locale: fr
            }
        }),
        NbSidebarModule.forRoot(),
        NbWindowModule.forRoot(),
        NbButtonModule,
        NbEvaIconsModule,
        NbIconModule,
        NbMenuModule.forRoot(),
        FormlyModule.forRoot({
            types: [
                {name: 'iban', component: FormlyIbanInputComponent},
                {name: 'categorySelect', component: FormlyCategorySelectComponent},
                {name: 'cseemployeStateSelect', component: FormlyCseemployeStateSelectComponent},
                {name: 'cseStateSelect', component: FormlyCseStateSelectComponent},
                {name: 'textarea', component: FormlyTextareaComponent},
                {name: 'currency', component: FormlyCurrencyInputComponent},
                {name: 'icon', component: FormlyIconInputComponent},
                {name: 'input', component: FormlyNgFieldInputComponent},
                {name: 'file', component: FormlyFileChooserComponent},
                {name: 'documentTypeSelect', component: FormlyDocumentTypeSelectComponent},
                {name: 'email', component: FormlyNgFieldInputComponent},
                {name: 'country', component: FormlyNgFieldCountryComponent},
                {name: 'date', component: FormlyNgFieldDateComponent},
                {name: 'toggle', component: FormlyNgFieldToggleComponent},
                {name: 'checkbox', component: FormlyNgFieldCheckboxComponent},
                {name: 'radio', component: FormlyNgFieldRadioComponent},
                {name: 'select', component: FormlyNgFieldSelectComponent},
                {name: 'annualTurnOverSelect', component: FormlyLegalAnnualTurnOverSelectComponent},
                {name: 'legalFormSelect', component: FormlyLegalFormSelectComponent},
                {name: 'legalNetIncomeRangeSelectComponent', component: FormlyLegalNetIncomeRangeSelectComponent},
                {
                    name: 'legalNumberOfEmployeeRangeSelectComponent',
                    component: FormlyLegalNumberOfEmployeeRangeSelectComponent
                },
                {name: 'phone', component: FormlyPhoneNumberComponent},
                {name: 'datetime', component: FormlyNgFieldDateTimeComponent},
                {name: 'amount', component: FormlyAmountInputComponent}
            ],
            wrappers: [
                {name: 'fieldset', component: FormlyNgWrapperFieldsetComponent}
            ]
        }),
        ContributionsModule
    ],
    providers: providers,
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(private injector: Injector) {    // Create global Service Injector.
        ServiceLocator.injector = this.injector;
    }
}
