import {Component} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
    selector: 'mcv-formly-ng-wrapper-fieldset',
    template: `
        <fieldset [disabled]="to.disabled">
            <legend>{{to.label}}</legend>
            <ng-container #fieldComponent></ng-container>
        </fieldset>`
})
export class FormlyNgWrapperFieldsetComponent extends FieldWrapper {
}
