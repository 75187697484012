import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@crm/app/auth/services/auth.service';
import {ConfigurationsService} from '@crm/app/auth/services/configurations.service';
import {AuthResult} from '@mcv/core';

@Component({
    selector: 'mcv-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private configurationService: ConfigurationsService,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        if (this.isQueryValid()) {
            const session: AuthResult = {
                token: this.activatedRoute.snapshot.queryParamMap.get('token'),
                expiresIn: +this.activatedRoute.snapshot.queryParamMap.get('expiresIn'),
                scope: this.activatedRoute.snapshot.queryParamMap.get('scope')
            };
            this.authService.setSession(session);
            this.configurationService.loadAppConfig()
                .toPromise()
                .then(_ => this.router.navigate(['/crm']));
        } else {
            this.router.navigate(['/auth/error'], {queryParams: {error: 'Jeton incorrect'}});
        }
    }

    readToken() {
        this.activatedRoute.snapshot.queryParamMap.has('token');
    }

    private isQueryValid() {
        return this.activatedRoute.snapshot.queryParamMap.has('token') &&
            this.activatedRoute.snapshot.queryParamMap.has('expiresIn') &&
            this.activatedRoute.snapshot.queryParamMap.has('scope');
    }
}
