<nb-form-field>
    <input (dateInput)="setDate($event)"
           (focus)="focus(picker)"
           [disabled]="disabled"
           [formControl]="dateControl"
           [ngxMatDatetimePicker]="picker"
           fullWidth
           nbInput/>
    <button (click)="picker.open()"
            [disabled]="disabled"
            ghost
            nbButton
            nbSuffix
            type="button">
        <nb-icon icon="calendar-alt" pack="far"></nb-icon>
    </button>
    <ngx-mat-datetime-picker #picker [enableMeridian]="false" [showSeconds]="true" [showSpinners]="true">
    </ngx-mat-datetime-picker>
</nb-form-field>
