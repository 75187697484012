import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {BaseRepository, CseEmployeTopup} from '@mcv/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CseEmployeTopupService extends BaseRepository<CseEmployeTopup> {
    model = 'CseEmployeTopup';

    constructor(protected httpClient: HttpClient, @Inject(BASEURL) baseUrl: string) {
        super(httpClient, baseUrl);
    }
}

