<div class="d-flex align-items-center">
    <span *ngIf="displayName" class="mr-2 name--span__weight">
       <span *ngIf="displayId" nbTooltip="{{affiliateUser.id}} @mcv">#{{affiliateUser.id}}</span>
        {{affiliateUser?.lastname | uppercase}} {{affiliateUser?.firstname | titlecase}}
    </span>
    <button (click)="openDetail()"
            *ngIf="displayDetail"
            nbButton
            nbTooltip="Ouvrir le détail"
            size="tiny"
            status="success">
        <nb-icon icon="eye" pack="far"></nb-icon>
    </button>
</div>
<div *ngIf="displayEmail && affiliateUser.email" class="mt-1">
    <a href="mailto:{{affiliateUser.email}}">{{affiliateUser.email}}</a>
</div>
<div *ngIf="displayPhone && affiliateUser.phone">
    Téléphone : <a href="tel:{{affiliateUser.phone}}">{{affiliateUser.phone}}</a>
</div>
<div *ngIf="displayMobile && affiliateUser.mobile">
    Téléphone : <a href="tel:{{affiliateUser.mobile}}">{{affiliateUser.mobile}}</a>
</div>
<div *ngIf="displayStatus && affiliateUser.state">
    Status :
    <mcv-badge [badgeClass]="affiliateUser.state | affiliateStateClass">{{affiliateUser.state}}</mcv-badge>
</div>
