import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {UiModule} from '@mcv/ui';
import {NbAlertModule, NbButtonModule, NbCardModule, NbLayoutModule, NbSpinnerModule} from '@nebular/theme';
import {AuthRoutingModule} from './auth-routing.module';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {IndexComponent} from './pages/index/index.component';
import {LoginComponent} from './pages/login/login.component';
import {AuthService} from './services/auth.service';
import {ConfigurationsService} from './services/configurations.service';
import {RegisterComponent} from './pages/register/register.component';
import {ErrorComponent} from './pages/error/error.component';

const configFactory = (configurationsService: ConfigurationsService,
    authService: AuthService): () => Promise<any> => {
    return () => {
        if (authService.isLoggedIn()) {
            return configurationsService.loadAppConfig()
                .toPromise();
        }
    };
};

@NgModule({
    declarations: [
        LoginComponent,
        IndexComponent,
        RegisterComponent,
        ErrorComponent
    ],
    imports: [
        UiModule,
        CommonModule,
        HttpClientModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        NbLayoutModule,
        NbCardModule,
        NbButtonModule,
        NbAlertModule,
        NbSpinnerModule
    ],
    providers: [
        AuthService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: APP_INITIALIZER, useFactory: configFactory, deps: [ConfigurationsService, AuthService], multi: true}
    ]
})
export class AuthModule {
}
