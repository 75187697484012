<button #filePicker="ngxFilePicker"
        (filePick)="onFilePicked($event)"
        [accept]='extensions'
        [disabled]="uploading"
        class="btn btn-primary"
        multiple
        ngxFilePicker="'readMode'"
        size="small"
        type="button">
    Parcourir l'ordinateur
</button>
